/* eslint-disable @typescript-eslint/unbound-method */
import {InputTextField, ValidatableInput} from '@hconnect/uikit/src/lib2'
import {Close} from '@mui/icons-material'
import {ButtonBase, Dialog, DialogContent, Typography} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {makeStyles} from 'tss-react/mui'

import CAN from './CAN.svg'
import {AccountFormComponentProps, AccountFormTypes, AccountFormValues} from './types'

const useStyles = makeStyles()((theme) => ({
  textField: {
    marginTop: theme.spacing(1)
  },
  infoIconButton: {
    marginBottom: theme.spacing(1),
    backgroundColor: 'transparent',
    color: '#7F9BA6',
    border: 'none',
    zIndex: 1000
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.grey[500],
    padding: theme.spacing(2),
    zIndex: 1000
  },
  dialogContent: {
    padding: theme.spacing(4)
  },
  dialogTitle: {
    letterSpacing: 2,
    marginBottom: theme.spacing(2)
  },
  dialogImage: {
    margin: `${theme.spacing(3)}px auto`,
    display: 'block',
    maxWidth: '100%'
  },
  infoBox: {
    color: theme.palette.secondary.main,
    fontSize: '16px'
  }
}))

interface Props extends AccountFormComponentProps {
  name: keyof AccountFormValues
  formMethods: AccountFormTypes
}

export const CustomerNumberField: React.FC<Props> = ({name, formMethods}) => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const [isCustomerNumberHintShown, setCustomerNumberHintShown] = useState(false)

  const error = formMethods?.formState.errors[name]

  const {ref: accountNumberRef, ...accountNumberRest} = formMethods.register(name)

  return (
    <>
      <Dialog
        open={isCustomerNumberHintShown}
        onBackdropClick={() => setCustomerNumberHintShown(false)}
        maxWidth="md"
        data-test-id="authenticator-access-form-canm-modal"
      >
        <ButtonBase
          className={classes.closeButton}
          aria-label="Close"
          onClick={() => setCustomerNumberHintShown(false)}
        >
          <Close />
        </ButtonBase>
        <DialogContent className={classes.dialogContent}>
          <Typography className={classes.dialogTitle} variant="h1" color="textPrimary" gutterBottom>
            {t('authenticator.requestAccess.whereIsMyCAN.title')}
          </Typography>
          <Typography variant="body2" color="textPrimary" gutterBottom>
            {t('authenticator.requestAccess.whereIsMyCAN.body')}
          </Typography>
          <img
            alt={t('authenticator.requestAccess.whereIsMyCAN.body')}
            className={classes.dialogImage}
            src={CAN}
            width={273}
            height={264}
          />
        </DialogContent>
      </Dialog>
      <ValidatableInput
        input={
          <InputTextField
            id="accountNumberField"
            inputRef={accountNumberRef}
            data-test-id="authenticator-access-form-account-number"
            label={t('authenticator.requestAccess.textfieldLabels.accountNumber')}
            error={!!error}
            required
            helperText={error && t(`authenticator.errorMessages.${error?.message}`)}
            {...accountNumberRest}
          />
        }
        onHint={() => setCustomerNumberHintShown(true)}
      />
      <div className={classes.infoBox}>
        {t('authenticator.requestAccess.textfieldLabels.accountNumberInfo')}
      </div>
    </>
  )
}
