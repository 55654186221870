import {resetPassword} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {Typography} from '@hconnect/uikit'
import {Paper} from '@mui/material'
import {AxiosError} from 'axios'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {api} from '../../api/api'
import {HCLinearProgress} from '../../Components/HCLinearProgress/HCLinearProgress'
import LoadingButton from '../../Components/LoadingButton'
import {PaperTitle} from '../../Components/PaperTitle'
import {useGlobalState} from '../../hooks/useGlobalState'
import {routes} from '../../routes'

import {useStyles} from './ResetPasswordLegacy.styles'

type PasswordStateOption =
  | 'PROGRESS'
  | 'DONE'
  | 'ERROR_NETWORK'
  | 'ERROR_USER_NOT_FOUND'
  | 'ERROR_SERVER'

export const ResetPasswordLegacy: React.FC = () => {
  const {globalState} = useGlobalState()
  const {classes} = useStyles()
  const history = useHistory()

  const [resetPasswordState, setResetPasswordState] = useState<PasswordStateOption>('PROGRESS')

  const statefulResetPassword = async (username: string) => {
    try {
      setResetPasswordState('PROGRESS')
      await resetPassword(api)(username)
      setResetPasswordState('DONE')
    } catch (error) {
      const e = error as AxiosError

      console.error(e)
      trackEvent('authError', {
        product: 'authenticator',
        date: new Date().toISOString(),
        errorCode: e.response?.status,
        component: 'ResetPasswordLegacy.tsx',
        endpoint: e.response?.config.url
      })

      const {response} = e
      if (!response) {
        setResetPasswordState('ERROR_NETWORK')
        return
      }

      const {status} = response
      if (status === 404) {
        setResetPasswordState('ERROR_USER_NOT_FOUND')
        return
      }

      setResetPasswordState('ERROR_SERVER')
    }
  }

  const navigateToSignIn = () => {
    history.replace('/')
  }

  useEffect(() => {
    // TODO check if username is still correctly implemented
    const {username} = globalState
    if (!username) {
      console.error('No username set in globalState. Redirecting to sign in')
      history.push(routes.SignIn)
      return
    }

    void statefulResetPassword(username)
  }, [globalState, history])

  const {t} = useTranslation()

  if (resetPasswordState === 'PROGRESS') {
    return (
      <Paper className={classes.paper} elevation={8}>
        <div
          data-test-id="authenticator-reset-password-in-progress"
          className={classes.resetPasswordLoading}
        >
          <div className={classes.resetPasswordLoadingElementContent}>
            <Typography className={classes.bodyText}>
              {t('authenticator.passwordReset.progress.title')}
            </Typography>
            <HCLinearProgress data-test-id="hc-linear-progress-bar" />
          </div>
        </div>
      </Paper>
    )
  }

  if (resetPasswordState === 'ERROR_USER_NOT_FOUND') {
    return (
      <Paper className={classes.paper} elevation={8}>
        <div data-test-id="authenticator-reset-password-user-not-found">
          <Typography className={classes.bodyText}>
            {t(`authenticator.passwordReset.userNotFound.body.${globalState.loginType}`)}
          </Typography>
          <LoadingButton
            fullWidth
            color="primary"
            variant="outlined"
            onClick={navigateToSignIn}
            data-test-id="authenticator-reset-password-abort"
          >
            {t('authenticator.passwordReset.userNotFound.button')}
          </LoadingButton>
        </div>
      </Paper>
    )
  }

  if (resetPasswordState === 'ERROR_NETWORK' || resetPasswordState === 'ERROR_SERVER') {
    return (
      <Paper className={classes.paper} elevation={8}>
        <div data-test-id="authenticator-reset-password-error-network">
          <PaperTitle isCentered subtitle={t('authenticator.passwordReset.error.title')} />
          <Typography className={classes.bodyText}>
            {t('authenticator.passwordReset.error.body')}
          </Typography>
          <LoadingButton
            fullWidth
            color="primary"
            variant="outlined"
            onClick={() => statefulResetPassword(globalState.username)}
            data-test-id="authenticator-reset-password-redo"
          >
            {t('authenticator.passwordReset.error.button')}
          </LoadingButton>
        </div>
      </Paper>
    )
  }

  return (
    <div data-test-id="authenticator-reset-password-success">
      <PaperTitle isCentered subtitle={t('authenticator.passwordReset.success.title')} />
      <Typography className={classes.bodyText}>
        {t('authenticator.passwordReset.success.body')}
      </Typography>
      <LoadingButton
        fullWidth
        color="primary"
        variant="outlined"
        onClick={navigateToSignIn}
        data-test-id="authenticator-reset-password-done"
      >
        {t('authenticator.passwordReset.success.button')}
      </LoadingButton>
    </div>
  )
}
