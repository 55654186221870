import {Product} from '@hconnect/apiclient'
import {Typography} from '@hconnect/uikit'
import {Button, Grid} from '@mui/material'
import classNames from 'classnames'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {LoginType} from '../../constants'
import {useGlobalState} from '../../hooks/useGlobalState'
import {useStyles} from '../../Pages/SignIn/SignIn.styles'
import {routes} from '../../routes'

type UsernameGroupProps = {
  isLoading: boolean
  error: string | null
  setError: (error: string | null) => void
}
export const UsernameGroup = ({error, isLoading, setError}: UsernameGroupProps) => {
  const {classes} = useStyles()
  const history = useHistory()
  const {globalState} = useGlobalState()
  const {t} = useTranslation()
  const isCarbonBank = globalState.clientId === Product.CarbonBank
  const onChangeUsername = () => {
    if (error !== null) {
      setError(null)
    }
    history.push(routes.SignIn)
  }

  return (
    <Grid
      component="div"
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>
        <Typography
          variant="body1"
          className={classes.usernameText}
          style={{pointerEvents: 'none'}}
          data-test-id="authenticator-identifier"
        >
          {globalState.loginType === LoginType.PHONE
            ? globalState.mobileNumber
            : globalState.username}
        </Typography>
      </Grid>
      <Grid item>
        <Button
          data-test-id="authenticator-change-identifier"
          variant="outlined"
          className={classNames(
            classes.linkButton,
            isCarbonBank ? classes.cbEnabledLink : classes.enabledLink,
            {
              [isCarbonBank ? classes.cbDisabledLink : classes.disabledLink]: isLoading
            }
          )}
          color="primary"
          onClick={() => {
            setError(null)
            if (!isLoading) {
              onChangeUsername()
            }
          }}
        >
          {t('authenticator.password.changeUsername')}
        </Button>
      </Grid>
    </Grid>
  )
}
