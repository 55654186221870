import {Product} from '@hconnect/apiclient'
import {Lock} from '@mui/icons-material'
import {Button, ButtonProps, CircularProgress} from '@mui/material'
import classNames from 'classnames'
import React from 'react'
import {makeStyles} from 'tss-react/mui'

import {useGlobalState} from '../hooks/useGlobalState'

const useStyles = makeStyles()((theme) => ({
  progressButtonWrapper: {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    outline: 'none',
    '& .MuiButtonBase-root': {
      backgroundColor: 'rgba(1, 106, 212, 1)',
      color: '#FFFFFF !important',
      '&.Mui-disabled': {
        opacity: 0.3,
        border: '1px solid rgba(1, 106, 212, 1)'
      },
      '&:hover': {
        backgroundColor: '#0162C4'
      }
    }
  },
  cbProgressButtonWrapper: {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    outline: 'none',
    '& .MuiButtonBase-root': {
      backgroundColor: 'rgba(0, 120, 55, 1)',
      color: '#FFFFFF !important',
      '&.Mui-disabled': {
        opacity: 0.3,
        border: '1px solid rgba(1, 106, 212, 1)'
      },
      '&:hover': {
        opacity: 0.7,
        backgroundColor: 'rgba(0, 120, 55, 1)'
      }
    }
  },
  buttonProgress: {
    position: 'absolute',
    bottom: theme.spacing(),
    left: '50%',
    marginLeft: -12
  }
}))

interface LoadingButtonProps extends ButtonProps {
  loading?: boolean
  btnClassName?: string
  progressClassName?: string
}

const LoadingButton: React.FC<LoadingButtonProps> = ({
  loading,
  children,
  className: classNameProp,
  btnClassName,
  progressClassName,
  startIcon,
  disabled,
  ...otherProps
}) => {
  const {classes} = useStyles()
  const {globalState} = useGlobalState()

  const isCarbonBank = globalState.clientId === Product.CarbonBank
  return (
    <div
      className={classNames(
        isCarbonBank ? classes.cbProgressButtonWrapper : classes.progressButtonWrapper,
        classNameProp
      )}
    >
      <Button
        disabled={loading || disabled}
        startIcon={loading || disabled ? <Lock style={{color: '#FFFFFF'}} /> : startIcon}
        {...otherProps}
        className={btnClassName}
      >
        {children}
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          className={classNames(classes.buttonProgress, progressClassName)}
        />
      )}
    </div>
  )
}

export default LoadingButton
