import {createHttpClientWithToken, createPublicHttpClient} from '@hconnect/apiclient'
import {AxiosInstance} from 'axios'

// In Authenticator, backend switch is not allowed due to security
// API Gateway is mapped to origin/api by Azure Front Door
// In development mode, local proxy is enabled, see: craco.config.js or search repo for: BACKEND_ORIGIN

const generatedUrl = window.location.origin.includes('build.hconnect.digital')
const backendSelector = {
  getSelectedBackend: () => ({
    API_URL: `${generatedUrl ? 'https://auth-qa.hconnect.digital' : window.location.origin}/api`,
    AUTH_URL: '',
    MHC_URL: ''
  })
}

export const api = createPublicHttpClient({
  backendSelector
})

export const createAuthHttpClient = (token: string): AxiosInstance =>
  createHttpClientWithToken({
    token,
    backendSelector
  })
