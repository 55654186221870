import {getCountries, retrieveResponseData} from '@hconnect/apiclient'
import {useQuery} from '@tanstack/react-query'

import {api} from '../../api/api'
import {useGlobalState} from '../../hooks/useGlobalState'

export const useCountries = () => {
  const response = useQuery(['getCountries'], retrieveResponseData(getCountries(api)))
  return response.data
}

export const useAvailableCountries = () => {
  const {globalState} = useGlobalState()

  const countries = useCountries()

  if (!countries) {
    return null
  }

  return countries.filter((country) => country.liveProducts.includes(globalState.clientId))
}
