import {Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {PaperTitle} from '../Components/PaperTitle'

export const InvalidSession = () => {
  const {t} = useTranslation()

  return (
    <Box data-test-id="invalid_session">
      <PaperTitle
        title={t('authenticator.invalidSession.title')}
        subtitle={t('authenticator.invalidSession.subtitle')}
        isCentered={false}
      />
    </Box>
  )
}
