import {createUser, CreationChannel, CreationProduct, Product} from '@hconnect/apiclient'
import {get} from 'lodash'

import {api} from '../../api/api'
import {login} from '../../api/user'
import {GlobalState} from '../../hooks/useGlobalState'
import {createOauthObject} from '../../utils'

export const createAccount = ({
  formValues,
  globalState,
  termsVersion
}: {
  formValues: any
  globalState: GlobalState
  termsVersion: string
}) => {
  const creationChannel: CreationChannel =
    globalState.source === 'webview' ? 'automaticInvite' : 'userInvite'

  const body = {
    username: get(formValues, 'username'),
    isTermsApprovalRequired: globalState.clientId === Product.OnSite,
    isActive: false,
    name: get(formValues, 'fullName'),
    eMail: get(formValues, 'email'),
    mobileNumber: get(formValues, 'mobileNumber'),
    companyName: get(formValues, 'company'),
    creationChannel,
    creationEvent: globalState.webviewType,
    country: get(formValues, 'country'),
    marketId: get(formValues, 'market'),
    password: get(formValues, 'password'),
    defaultLocale: get(formValues, 'defaultLocale', 'en-US'),
    creationProduct: globalState.clientId as CreationProduct,
    termsAcceptedOn: termsVersion ? new Date().toISOString() : null,
    termsAcceptedVersion: termsVersion ? termsVersion : null
  }

  return createUser(api)(body)
}

export const doLogin = async (username, password, globalState) => {
  const oauthParams = createOauthObject(globalState)
  const tokenInfo = await login(api)(username, password, oauthParams)
  return {tokenInfo, oauthParams, username}
}
