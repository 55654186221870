import {useLatestDocumentVersion} from '@hconnect/common/hooks/legal/useLegalDocumentsVersions'
import {
  HConnectLogoComponent,
  LegalDocumentType,
  TermsAndPrivacyModal,
  Typography
} from '@hconnect/uikit'
import {Link} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {useGlobalState} from '../../hooks/useGlobalState'
import {useStyles} from '../../Pages/CreateAccount/CreateAccount.styles'
import {getBrowserLanguage} from '../../utils'

import {
  AccessFormType,
  AccountFormComponentProps,
  AccountFormTypes,
  AccountFormValues
} from './types'

interface Props extends Omit<AccountFormComponentProps, 'name'> {
  setTermsVersion: React.Dispatch<React.SetStateAction<string | null>>
  formType: AccessFormType
  formMethods: AccountFormTypes
}

export const TermsAndConditions: React.FC<Props> = ({
  formMethods: {watch},
  setTermsVersion,
  formType
}) => {
  const {classes} = useStyles()
  const [documentType, setDocumentType] = useState<LegalDocumentType>(LegalDocumentType.NONE)
  const [showDisclaimer, setShowDisclaimer] = useState(false)
  const {globalState} = useGlobalState()
  const {i18n} = useTranslation()

  const country: AccountFormValues['country'] = watch('country')

  const latestTcVersionQueryInfo = useLatestDocumentVersion('tc', country?.toLowerCase())
  const latestTcVersion = latestTcVersionQueryInfo?.lastAvailableDocumentVersion?.version

  useEffect(() => {
    if (formType !== 'CREATE_ACCOUNT') return

    const hubReceiveInvite = globalState.redirectUrl.pathname.startsWith('/receiveInvite')
    const roleRequestId = !!globalState.roleRequestId

    if (!hubReceiveInvite && !roleRequestId) {
      return
    }

    setShowDisclaimer(true)
  }, [formType, globalState.redirectUrl, globalState.roleRequestId])

  useEffect(() => {
    if (!country) {
      setTermsVersion(null)
      setDocumentType(LegalDocumentType.NONE)
      return
    }

    if (!latestTcVersion) return

    setTermsVersion(String(latestTcVersion))
  }, [setTermsVersion, latestTcVersion, country])

  if (!country) {
    return null
  }

  const languageFromGlobalState = globalState.lng.split('-')[0]
  const preferredLanguage = !languageFromGlobalState
    ? getBrowserLanguage()
    : languageFromGlobalState

  return (
    <div className={classes.termsCheckbox}>
      {showDisclaimer && (
        <>
          <Typography
            data-test-id="authenticator-invite-disclaimer"
            variant="subtitle2"
            color="textPrimary"
          >
            <Trans i18nKey="authenticator.footer.disclaimer" />
          </Typography>
          <br />
        </>
      )}
      <Typography variant="subtitle2" className={classes.termsCheckboxText}>
        <Trans i18nKey="authenticator.legal.prefix" />
        <br />
        <Link
          className={classes.link}
          underline="hover"
          onClick={(e) => {
            e.stopPropagation()
            setDocumentType(LegalDocumentType.TERMS)
          }}
        >
          <Trans i18nKey="authenticator.legal.terms and conditions" />
        </Link>
        &nbsp;
        <Trans i18nKey="authenticator.legal.between" />
        &nbsp;
        <Link
          className={classes.link}
          underline="hover"
          onClick={(e) => {
            e.stopPropagation()
            setDocumentType(LegalDocumentType.PRIVACY)
          }}
        >
          <Trans i18nKey="authenticator.legal.privacy" />
        </Link>
        .
      </Typography>
      <TermsAndPrivacyModal
        logo={HConnectLogoComponent}
        documentType={documentType}
        onClose={() => {
          setDocumentType(LegalDocumentType.NONE)
        }}
        onAccept={() => {
          setDocumentType(LegalDocumentType.NONE)
        }}
        country={country}
        showFooter={false}
        hideAcceptanceTitle={true}
        preferredLanguage={preferredLanguage}
        onLanguageChange={(languageCode: string) => {
          void i18n.changeLanguage(languageCode)
        }}
      />
    </div>
  )
}
