import {useQuery} from '@tanstack/react-query'
import {AxiosError, AxiosInstance} from 'axios'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {api} from '../../../api/api'
import {LoginType} from '../../../constants'
import {useGlobalState} from '../../../hooks/useGlobalState'
import {routes} from '../../../routes'

type FullPhoneNumber = {
  fullPhoneNumber: string
}
export const usePhoneNumber = (
  phoneNumber: string,
  setInputMobileNumberError: (error: string | null) => void,
  setInputEmailError: (error: string | null) => void,
  setTriggerPasswordlessFlow: (trigger: boolean) => void,
  isPasswordlessFlow: boolean
) => {
  const history = useHistory()
  const {setGlobalState} = useGlobalState()
  const {t} = useTranslation()

  const phoneNumberFetcher = (api: AxiosInstance) => async (): Promise<FullPhoneNumber> => {
    const response = await api.get<FullPhoneNumber>(
      `/identity/account/getByPhoneNumber?phoneNumber=${phoneNumber}`
    )
    return response.data
  }

  const {data, isFetching, isError, refetch} = useQuery<FullPhoneNumber, AxiosError>(
    ['fullPhoneNumber', {phoneNumber}],
    phoneNumberFetcher(api),
    {
      enabled: false,
      cacheTime: 0,
      staleTime: 0,
      retry: 1,
      onSuccess: ({fullPhoneNumber}) => {
        setGlobalState((globalState) => ({
          ...globalState,
          email: '',
          username: fullPhoneNumber,
          loginType: LoginType.PHONE
        }))
        setInputEmailError(null)
        setInputMobileNumberError(null)
        if (isPasswordlessFlow) {
          setTriggerPasswordlessFlow(true)
        } else {
          history.push(routes.Password)
        }
      },
      onError: (error) => {
        const errorCode = error?.response?.status
        if (errorCode === 409) {
          setInputMobileNumberError(t('authenticator.signIn.missingCountryCode'))
        }

        if (errorCode === 404) {
          if (isPasswordlessFlow) {
            setTriggerPasswordlessFlow(true)
          } else {
            setInputMobileNumberError(t('authenticator.signIn.noPhoneNumberFound'))
          }
        }

        if (errorCode !== 409 && errorCode !== 404) {
          setInputMobileNumberError(t('authenticator.signIn.error.unknown'))
        }
      }
    }
  )

  return {
    fullPhoneNumber: data?.fullPhoneNumber,
    isLoading: isFetching,
    isError,
    refetch
  }
}
