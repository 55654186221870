import {Product} from '@hconnect/apiclient'
import {Box, Button} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {makeStyles} from 'tss-react/mui'

import {PaperTitle} from '../../Components/PaperTitle'
import {useGlobalState} from '../../hooks/useGlobalState'
import {routes} from '../../routes'

const useStyles = makeStyles()(() => ({
  submitButton: {
    padding: '13px 16px',
    width: '100%',
    textTransform: 'none',
    border: '1px solid #016AD4',
    borderRadius: '6px',
    backgroundColor: '#016AD4',
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.08)',
    color: '#FFFFFF',
    '&.Mui-disabled': {
      color: '#FFFFFF',
      opacity: 0.3,
      border: '1px solid rgba(1, 106, 212, 1)'
    },
    '&:hover': {
      backgroundColor: 'rgba(1, 106, 212, 1)',
      opacity: 0.3
    }
  },
  cbSubmitButton: {
    padding: '13px 16px',
    width: '100%',
    textTransform: 'none',
    border: '1px solid rgba(0, 120, 55, 1)',
    borderRadius: '6px',
    backgroundColor: 'rgba(0, 120, 55, 1)',
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.08)',
    color: '#FFFFFF',
    '&.Mui-disabled': {
      color: '#FFFFFF',
      opacity: 0.3,
      border: '1px solid rgba(1, 106, 212, 1)'
    },
    '&:hover': {
      backgroundColor: 'rgba(1, 106, 212, 1)',
      opacity: 0.3
    }
  }
}))

export const CreateAccountSuccess: React.FC = () => {
  const {classes} = useStyles()
  const {t} = useTranslation()
  const history = useHistory()
  const {globalState} = useGlobalState()
  const isCarbonBank = globalState.clientId === Product.CarbonBank

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
      }}
    >
      <PaperTitle
        title={t('authenticator.createAccount.success.title')}
        subtitle={t('authenticator.createAccount.success.subTitle', {
          product: globalState.clientId
        })}
        isCentered={false}
      />

      <Button
        className={isCarbonBank ? classes.cbSubmitButton : classes.submitButton}
        onClick={() => history.push({pathname: routes.SignIn, search: window.location.search})}
        variant="outlined"
        color="primary"
        size="large"
      >
        {t('authenticator.createAccount.success.button')}
      </Button>
    </Box>
  )
}
