import {Country} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {AxiosError} from 'axios'
import {useSnackbar} from 'notistack'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {api} from '../../api/api'
import {loginForAuthCode} from '../../api/user'
import {AccountForm} from '../../Components/AccountForm/AccountForm'
import {AccountFormTypes, AccountFormValues} from '../../Components/AccountForm/types'
import {PaperTitle} from '../../Components/PaperTitle'
import {handleValidationError} from '../../errorHandling'
import {useGlobalState} from '../../hooks/useGlobalState'
import {routes} from '../../routes'
import {calcDefaultLocale, getBrowserLanguage} from '../../utils'
import {redirect} from '../SignIn/SignIn'
import {formatIdentityServerReturnUrl} from '../SignIn/utils/formatIdentityServerReturnUrl'

import {createAccount, doLogin} from './CreateAccount.actions'

export const CreateAccount = () => {
  const history = useHistory()
  const {t} = useTranslation()
  const {globalState, setGlobalState} = useGlobalState()

  const {enqueueSnackbar} = useSnackbar()

  const languageFromGlobalState = globalState.lng
  const preferredLanguage = !languageFromGlobalState
    ? getBrowserLanguage()
    : languageFromGlobalState

  const onSubmit = async (
    formValues: AccountFormValues,
    formMethods: AccountFormTypes,
    termsVersion: string,
    selectedCountry: Country
  ) => {
    const defaultLocale = calcDefaultLocale(selectedCountry, preferredLanguage)

    try {
      await createAccount({formValues: {...formValues, defaultLocale}, globalState, termsVersion})
    } catch (e) {
      const error = e as AxiosError

      trackEvent('authError', {
        product: 'authenticator',
        date: new Date().toISOString(),
        errorCode: error?.response?.status,
        component: 'CreateAccount.tsx',
        endpoint: error?.response?.config.url
      })

      const errorKey = handleValidationError(error, formValues, formMethods)
      if (errorKey) {
        enqueueSnackbar(t(`authenticator.errorMessages.${errorKey}`), {
          variant: 'error'
        })
      }
      return
    }

    try {
      if (globalState.identityServerReturnUrl) {
        const validIdentityServerUrl = formatIdentityServerReturnUrl(
          globalState.identityServerReturnUrl
        )

        const authCodeResponse = await loginForAuthCode(api)(
          formValues.email || formValues.mobileNumber,
          formValues.password,
          validIdentityServerUrl
            ? validIdentityServerUrl.toString()
            : globalState.identityServerReturnUrl.toString(),
          false,
          null,
          null
        )

        setGlobalState((g) => ({
          ...g,
          identityServerReturnUrl: authCodeResponse.idsReturnUrl
        }))

        let identityServerReturnUrlOrigin = authCodeResponse.idsReturnUrl.origin
        if (!identityServerReturnUrlOrigin) {
          console.error('Invalid url error')
          identityServerReturnUrlOrigin = new URL(authCodeResponse.idsReturnUrl as string).origin
        }
        const relativePath = authCodeResponse.idsReturnUrl
          .toString()
          .substring(identityServerReturnUrlOrigin.length)

        console.warn('redirecting to relativePath')
        console.warn(relativePath)

        window.location = relativePath
        return
      } else {
        const {tokenInfo, oauthParams, username} = await doLogin(
          formValues.email || formValues.mobileNumber,
          formValues.password,
          globalState
        )

        redirect({
          window,
          token: tokenInfo,
          oauthParams,
          username,
          redirectReason: 'CREATE_ACCOUNT'
        })
      }
    } catch (error) {
      const e = error as AxiosError

      console.error(e)
      trackEvent('authError', {
        product: 'authenticator',
        date: new Date().toISOString(),
        errorCode: e.response?.status,
        component: 'CreateAccount.tsx',
        endpoint: e.response?.config.url
      })

      setGlobalState({
        ...globalState,
        mobileNumber: formValues.mobileNumber,
        email: formValues.email
      })
      history.push({pathname: routes.CreateAccountSuccess, search: window.location.search})
    }
  }

  return (
    <>
      <PaperTitle
        title={t('authenticator.createAccount.title')}
        subtitle={t('authenticator.createAccount.subtitle', {product: globalState.clientId})}
        isCentered={false}
      />

      <AccountForm onSubmit={onSubmit} formType="CREATE_ACCOUNT" />
    </>
  )
}
