import {LinearProgress, LinearProgressProps} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
  colorPrimary: {
    backgroundColor: 'rgba(41, 170, 255, 0.4)'
  },
  barColorPrimary: {
    backgroundColor: 'rgb(41, 170, 255)'
  }
}))
export const HCLinearProgress = (props: LinearProgressProps) => {
  const {classes} = useStyles()
  return (
    <LinearProgress
      classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary}}
      {...props}
    />
  )
}
