/* eslint-disable max-statements */
import {buildUrl, parseUrl, Product, Token} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {AxiosError} from 'axios'
import jwtDecode from 'jwt-decode'
import {get, some} from 'lodash'
import queryString from 'query-string'
import React, {useEffect, useRef, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'

import {api} from '../../api/api'
import {login, loginForAuthCode} from '../../api/user'
import {RedirectReason} from '../../constants'
import {getErrorKey} from '../../errorHandling'
import {useGlobalState} from '../../hooks/useGlobalState'
import {routes} from '../../routes'
import {createOauthObject, getSigninOptions, OAuthObject} from '../../utils'

import {StepPassword} from './StepPassword'
import {StepUsername} from './StepUsername'
import {formatIdentityServerReturnUrl} from './utils/formatIdentityServerReturnUrl'

const formErrorKeys = ['401_LOGIN_FAILURE']

export const isFormError = (errorKey: string): boolean =>
  some(formErrorKeys, (key) => key === errorKey)

export const redirect = ({
  oauthParams,
  token,
  redirectReason,
  username,
  window
}: {
  window
  token: Token
  oauthParams: OAuthObject
  username: string
  redirectReason: RedirectReason
}): void => {
  const {redirect_uri, state} = oauthParams
  const {access_token, expires_in, token_type, refresh_token} = token
  const parsedRedirectUrl = parseUrl(redirect_uri.href)
  const originalParams = parsedRedirectUrl.search ? queryString.parse(parsedRedirectUrl.search) : {}

  const search = queryString.stringify({
    ...originalParams,
    state,
    access_token,
    expires_in,
    token_type,
    refresh_token,
    redirect_reason: redirectReason,
    username
  })

  window.location = buildUrl({
    ...parsedRedirectUrl,
    search
  })
}

// eslint-disable-next-line complexity
export const SignIn: React.FC = () => {
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setLoading] = useState(false)
  const history = useHistory()
  const location = useLocation()

  const usernameTextFieldRef = useRef<HTMLInputElement>()
  const passwordTextFieldRef = useRef<HTMLInputElement>()

  const {globalState, setGlobalState} = useGlobalState()
  // TODO: @MHC
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [mfaError, setMfaError] = useState<string | null>(null)

  const {
    clientId,
    country,
    redirectUrl,
    isPasswordlessSignInEnabled,
    isPasswordfulSignInEnabled,
    identityServerReturnUrl
  } = globalState

  // FIXME pull into StepUsername
  useEffect(() => {
    if (location.pathname === routes.SignIn && usernameTextFieldRef.current) {
      usernameTextFieldRef.current.focus()
    } else if (location.pathname === routes.Password && passwordTextFieldRef.current) {
      passwordTextFieldRef.current.focus()
    }
  }, [location.pathname, globalState.loginType])

  useEffect(() => {
    const fetchSigninOptions = async () => {
      const signinOptions = await getSigninOptions(redirectUrl, clientId, country)

      setGlobalState((g) => ({
        ...g,
        isPasswordlessSignInEnabled: signinOptions.isPasswordlessSignInEnabled,
        isPasswordfulSignInEnabled: signinOptions.isPasswordfulSignInEnabled
      }))
    }

    if (isPasswordlessSignInEnabled === null || isPasswordfulSignInEnabled === null) {
      void fetchSigninOptions()
    }
  }, [
    isPasswordlessSignInEnabled,
    isPasswordfulSignInEnabled,
    setGlobalState,
    clientId,
    country,
    redirectUrl
  ])

  useEffect(() => {
    if (identityServerReturnUrl && clientId === Product.CRM) {
      window.location.href =
        window.location.origin +
        '/api/identity/api/aad/login?idsReturnUrl=' +
        encodeURIComponent(identityServerReturnUrl.toString())
    }
  }, [identityServerReturnUrl])

  // eslint-disable-next-line complexity
  const onPasswordSubmit = async () => {
    setError(null)
    setLoading(true)

    const oauthParams = createOauthObject(globalState)
    const {username, password, identityServerReturnUrl} = globalState

    try {
      if (identityServerReturnUrl) {
        const validIdentityServerUrl = formatIdentityServerReturnUrl(
          identityServerReturnUrl instanceof URL
            ? identityServerReturnUrl
            : new URL(identityServerReturnUrl)
        )

        const authCodeResponse = await loginForAuthCode(api)(
          username,
          password,
          validIdentityServerUrl
            ? validIdentityServerUrl.toString()
            : identityServerReturnUrl.toString(),
          false,
          null,
          null
        )

        setLoading(false)

        if (!authCodeResponse.tfaType || authCodeResponse.tfaType === 'none') {
          setGlobalState((g) => ({
            ...g,
            identityServerReturnUrl: authCodeResponse.idsReturnUrl
          }))
        }

        if (authCodeResponse.tfaType && authCodeResponse.tfaType !== 'none') {
          history.push(routes.InsertQuickCode, {tfaType: authCodeResponse.tfaType})
          return
        }

        if (authCodeResponse.isPasswordChangeRequired) {
          history.push(routes.ChangePassword)
          return
        }
      } else {
        const tokenInfo = await login(api)(username, password, oauthParams)
        setLoading(false)

        const decoded = jwtDecode(tokenInfo.access_token)

        const isPasswordChangeRequired =
          get(decoded, ['isPasswordChangeRequired'], 'false').toLowerCase() === 'true'

        const tfaType = get(decoded, ['tfaType'], 'none')

        if (isPasswordChangeRequired) {
          history.push(routes.ChangePassword)
          return
        }

        if (tfaType !== 'none') {
          history.push(routes.InsertQuickCode, {tfaType})
          return
        }

        setGlobalState((g) => ({
          ...g,
          token: tokenInfo
        }))
      }

      history.push(routes.RequestUserDataVerification)
    } catch (error) {
      const e = error as AxiosError

      console.error(e)
      trackEvent('authError', {
        product: 'authenticator',
        date: new Date().toISOString(),
        errorCode: e.response?.status,
        component: 'SignIn.tsx',
        endpoint: e.response?.config.url
      })

      setError(getErrorKey(e))
      setLoading(false)
    }
  }
  const handleADloginShortcut = (e) => {
    if (e.ctrlKey && e.shiftKey && e.code === 'KeyA') {
      const urlParams = new URLSearchParams(window.location.search)
      const idsReturnUrl = urlParams.get('ids_return_url')
      if (idsReturnUrl) {
        window.location.href =
          window.location.origin +
          '/api/identity/api/aad/login?idsReturnUrl=' +
          encodeURIComponent(idsReturnUrl)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleADloginShortcut)
    return () => window.removeEventListener('keydown', handleADloginShortcut)
  }, [])

  // The components STEP_SIGNIN, STEP_PASSWORD, ... get mounted but may be invisible.
  // This is necessary for the browser to *see both* username and password
  // in the DOM and store it in the password manager
  return (
    <>
      <StepUsername usernameTextFieldRef={usernameTextFieldRef} error={error} />
      <StepPassword
        passwordTextFieldRef={passwordTextFieldRef}
        error={error}
        mfaError={mfaError}
        isLoading={isLoading}
        onPasswordSubmit={onPasswordSubmit}
        setError={setError}
        location={location}
      />
    </>
  )
}
