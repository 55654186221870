import {Channel} from '@hconnect/apiclient'
import {AxiosInstance} from 'axios'

import {OAuthObject} from '../utils'

export const login =
  (api: AxiosInstance) => async (username: string, password: string, oauthParams: OAuthObject) => {
    const grant_type = 'password'

    if (!oauthParams.client_id) {
      console.error('client_id is not set. Did you load this page directly?')
    }

    const body = {
      username,
      password,
      grant_type,
      ...oauthParams
    }

    const response = await api.post('/users/login', body)
    return response.data
  }

export const logoutCurrentUser = (api: AxiosInstance) => async (logoutId: string) => {
  const response = await api.get(`/identity/account/logout?logoutId=${logoutId}`, {
    withCredentials: true
  })
  return response.data
}

export const getCurrentUserInfo = (api: AxiosInstance) => async () => {
  const response = await api.get('/identity/account/info', {
    withCredentials: true
  })
  return response.data
}

export const changeUserPasswordForAuthCode =
  (api: AxiosInstance) => async (oldPassword: string, newPassword: string) => {
    const body = {
      oldPassword,
      newPassword
    }

    const response = await api.post('/identity/account/changePassword', body, {
      withCredentials: true
    })

    return response.data
  }

export const loginForAuthCode =
  (api: AxiosInstance) =>
  async (
    username: string,
    password: string,
    idsReturnUrl: string,
    isOneTimePassword: boolean,
    channel: Channel | null,
    startToken: string | null,
    isChangePasswordSuccess?: boolean
  ) => {
    if (!idsReturnUrl) {
      console.error('return url is not provided')
    }

    const body = {
      username,
      password,
      idsReturnUrl,
      isOneTimePassword,
      channel,
      startToken,
      isChangePasswordSuccess: !!isChangePasswordSuccess
    }

    const response = await api.post('/identity/account/login', body, {
      withCredentials: true
    })
    return response.data
  }

export const loginTwoStep =
  (api: AxiosInstance) =>
  async (
    username: string,
    password: string,
    idsReturnUrl: string,
    isOneTimePassword: boolean,
    channel: Channel | null,
    startToken: string | null,
    twoFactorCode: string,
    isRecoveryCode: boolean
  ) => {
    if (!idsReturnUrl) {
      console.error('return url is not provided')
    }

    const body = {
      username,
      password,
      idsReturnUrl,
      isOneTimePassword,
      channel,
      startToken,
      twoFactorCode,
      isRecoveryCode
    }

    const response = await api.post('/identity/account/logintwostep', body, {
      withCredentials: true
    })
    return response.data
  }

export const userCanUseADLogin = (api: AxiosInstance) => async (email: string) => {
  if (!email) {
    console.error('email is not provided')
  }

  const response = await api.post('/identity/api/aad/canUseAdLogin', email, {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  })

  return response.data
}
