import {Product} from '@hconnect/apiclient'
import {Button} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useGlobalState} from '../../hooks/useGlobalState'
import {useStyles} from '../../Pages/RequestAccess/AccessForm.styles'
import {HCLinearProgress} from '../HCLinearProgress/HCLinearProgress'

import {AccessFormType} from './types'

type Props = {
  disabled: boolean
  formType: AccessFormType
  loading: boolean
}

export const SubmitButton: React.FC<Props> = ({disabled, loading, formType}) => {
  const {classes} = useStyles()
  const {t} = useTranslation()
  const {globalState} = useGlobalState()

  const isCarbonBank = globalState.clientId === Product.CarbonBank

  const calcTitle = () => {
    const title =
      formType === 'CREATE_ACCOUNT'
        ? t('authenticator.createAccount.submitButton')
        : t('authenticator.requestAccess.submit')

    return `${t('authenticator.footer.acceptAll')} & ${title}`
  }

  return (
    <>
      <Button
        data-test-id="create-account-submit-form"
        disabled={disabled || loading}
        className={isCarbonBank ? classes.cbSubmitButton : classes.submitButton}
        color="primary"
        size="large"
        type="submit"
      >
        {calcTitle()}
      </Button>
      {loading && <HCLinearProgress className={classes.loadingIndicator} />}
    </>
  )
}
