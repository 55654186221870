import {trackEventWithBrowserProps} from '@hconnect/common/logging/Analytics'
import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

import {useGlobalState} from './hooks/useGlobalState'

export const TraceHistory = () => {
  const location = useLocation()
  const {globalState} = useGlobalState()
  useEffect(() => {
    trackEventWithBrowserProps('authPageView', {
      product: 'authenticator',
      clientId: globalState.clientId,
      username: globalState.username,
      country: globalState.country,
      userLanguage: navigator.language,
      timestamp: new Date().toISOString(),
      pagePath: location.pathname,
      pageUrl: document.location.href,
      pageTitle: document.title
    })
    // eslint-disable-next-line
  }, [location])

  return null
}
