import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  additionalInorfmationContainer: {
    paddingTop: '30px'
  },
  infoIconButton: {
    marginBottom: theme.spacing(1),
    backgroundColor: 'transparent',
    color: '#7F9BA6',
    border: 'none',
    zIndex: 1000
  },
  submitButton: {
    padding: '13px 16px',
    width: '100%',
    textTransform: 'none',
    border: '1px solid #016AD4',
    borderRadius: '6px',
    backgroundColor: '#016AD4',
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.08)',
    color: '#FFFFFF',
    '&.Mui-disabled': {
      color: '#FFFFFF',
      opacity: 0.3,
      border: '1px solid rgba(1, 106, 212, 1)'
    },
    '&:hover': {
      backgroundColor: 'rgba(1, 106, 212, 1)',
      opacity: 0.3
    }
  },
  cbSubmitButton: {
    padding: '13px 16px',
    width: '100%',
    textTransform: 'none',
    border: '1px solid rgba(0, 120, 55, 1)',
    borderRadius: '6px',
    backgroundColor: 'rgba(0, 120, 55, 1)',
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.08)',
    color: '#FFFFFF',
    '&.Mui-disabled': {
      color: '#FFFFFF',
      opacity: 0.3,
      border: '1px solid rgba(1, 106, 212, 1)'
    },
    '&:hover': {
      backgroundColor: 'rgba(1, 106, 212, 1)',
      opacity: 0.3
    }
  },
  outlinedTextField: {
    '& > div': {
      minHeight: '100px'
    }
  },
  linkContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingTop: '30px'
  },
  loadingIndicator: {
    width: '100%'
  },

  showPasswordCheckbox: {
    padding: 0,
    marginRight: theme.spacing(),
    boxShadow: 'none',
    border: 'none',
    transition: 'none',
    '&:hover': {
      border: 'none',
      boxShadow: 'none'
    },
    '&:active': {
      border: 'none',
      boxShadow: 'none'
    }
  },
  showPasswordLabel: {
    fontSize: 14
  },

  visiblePassword: {
    whiteSpace: 'pre-wrap',
    fontWeight: 'bold',
    fontSize: 16
  },
  loadingIndicatorContainer: {
    justifyContent: 'center',
    display: 'flex'
  },
  textField: {
    marginTop: theme.spacing(1)
  },
  wrappedTextField: {
    whiteSpace: 'pre-wrap'
  },
  termsCheckbox: {
    wordWrap: 'break-word'
  },
  termsCheckboxText: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#435A70',
    wordWrap: 'break-word'
  },
  link: {
    cursor: 'pointer',
    color: '#016AD4'
  },
  cbLink: {
    cursor: 'pointer',
    color: 'rgba(0, 120, 55, 1)'
  }
}))
