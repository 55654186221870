import {Country, createUser, CreationChannel, CreationProduct, Product} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {Link} from '@mui/material'
import {AxiosError} from 'axios'
import {useSnackbar} from 'notistack'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {useHistory, useLocation} from 'react-router-dom'

import {api} from '../../api/api'
import {AccountForm} from '../../Components/AccountForm/AccountForm'
import {AccountFormTypes, AccountFormValues} from '../../Components/AccountForm/types'
import {PaperTitle} from '../../Components/PaperTitle'
import {LoginType} from '../../constants'
import {handleValidationError} from '../../errorHandling'
import {useGlobalState} from '../../hooks/useGlobalState'
import {routes} from '../../routes'
import {HistoryType} from '../../types'
import {calcDefaultLocale, getBrowserLanguage} from '../../utils'

import {useStyles} from './AccessForm.styles'

export const RequestAccess: React.FC = () => {
  const {enqueueSnackbar} = useSnackbar()
  const {t} = useTranslation()
  const {classes} = useStyles()
  const {globalState} = useGlobalState()
  const history = useHistory()
  const location = useLocation<HistoryType>()

  /*
  redirects the user to HUB or onSite
  */
  const goToConfirmation = () => {
    history.push(routes.RequestAccessSuccess)
  }

  const languageFromGlobalState = globalState.lng
  const preferredLanguage = !languageFromGlobalState
    ? getBrowserLanguage()
    : languageFromGlobalState

  const onSubmit = async (
    formValues: AccountFormValues,
    formMethods: AccountFormTypes,
    termsVersion: string,
    selectedCountry: Country
  ) => {
    const defaultLocale = calcDefaultLocale(selectedCountry, preferredLanguage)

    const selectedMarket = selectedCountry?.markets.find(
      (market) => market.marketId === formValues.market
    ) ?? {
      marketName: '',
      marketId: formValues.market
    }

    const body = {
      username: formValues.fullName,
      isTermsApprovalRequired: globalState.clientId === Product.OnSite,
      name: formValues.fullName,
      eMail: formValues.email,
      mobileNumber: formValues.mobileNumber,
      companyName: formValues.company,
      creationChannel: 'inAuthRegistration' as CreationChannel,
      creationProduct: globalState.clientId as CreationProduct,
      defaultLocale,
      requestedCustomerNumber: JSON.stringify({
        accountNumber: formValues.accountNumber,
        extraInfo: `${formValues.additionalInfo ?? ''} --- ${selectedMarket.marketName}`,
        market: selectedMarket.marketId
      }),
      marketId: formValues.market,
      country: formValues.country,
      termsAcceptedOn: new Date().toISOString(),
      termsAcceptedVersion: termsVersion,
      twoFactorEnabled: formValues.twoFactorEnabled
    }

    try {
      await createUser(api)(body)
      goToConfirmation()
    } catch (e) {
      const error = e as AxiosError
      console.error(error)
      trackEvent('authError', {
        product: 'authenticator',
        date: new Date().toISOString(),
        errorCode: error?.response?.status,
        component: 'RequestAccess.tsx',
        endpoint: error?.response?.config.url
      })

      const errorKey = handleValidationError(error, formValues, formMethods)
      if (errorKey) {
        enqueueSnackbar(t(`authenticator.errorMessages.${errorKey}`), {
          variant: 'error'
        })
      }
      return
    }
  }

  const alternativeLoginType =
    globalState.loginType === LoginType.EMAIL ? 'phoneNumber' : 'emailAddress'
  const alternativeLoginTypeLocalizationKey = t(
    `authenticator.requestAccess.${alternativeLoginType}`
  )
  // if user gets redirected here from trying to sign in and
  // no account was found we ask him to try with his other option
  // (email or mobile phone)
  const Title = () => {
    if (location?.state?.accountNotFound) {
      return (
        <PaperTitle
          title={t('authenticator.requestAccess.titleAccountNotFound')}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore type incorrectly doesn't allow React Element as subtitle
          subtitle={
            <Trans i18nKey="authenticator.requestAccess.subtitleAccountNotFound">
              If you signed in before with your {alternativeLoginTypeLocalizationKey} try
              <Link
                className={classes.link}
                onClick={() => {
                  history.push({pathname: routes.SignIn, search: window.location.search})
                }}
              >
                logging in
              </Link>
              again. Or create an account in a few steps now.
            </Trans>
          }
          isCentered={false}
        />
      )
    }
    return <PaperTitle title={t('authenticator.requestAccess.title')} isCentered={false} />
  }

  return (
    <>
      <Title />

      <AccountForm formType="REQUEST_ACCESS" onSubmit={onSubmit} />
    </>
  )
}
