import {Channel, passwordlessStart, PasswordlessStartParams, Product} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {getLocale, Typography} from '@hconnect/uikit'
import {NotInterested} from '@mui/icons-material'
import {Alert, AlertTitle, Button, Grid, Link} from '@mui/material'
import classNames from 'classnames'
import {useSnackbar} from 'notistack'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {api} from '../../api/api'
import {HCLinearProgress} from '../../Components/HCLinearProgress/HCLinearProgress'
import LoadingButton from '../../Components/LoadingButton'
import {clientSecrets, LoginType} from '../../constants'
import {useGlobalState} from '../../hooks/useGlobalState'
import {routes} from '../../routes'

import {useStyles} from './SignIn.styles'

export const UserLocked: React.FC = () => {
  const {classes} = useStyles()
  const {t} = useTranslation()
  const history = useHistory()
  const {enqueueSnackbar} = useSnackbar()
  const {globalState, setGlobalState} = useGlobalState()
  const isCarbonBank = globalState.clientId === Product.CarbonBank
  const [isResetPasswordLoading, setResetPasswordLoading] = useState(false)

  const onChangeUsername = () => {
    history.push(routes.SignIn)
  }

  const resetPasswordPasswordless = async () => {
    const {clientId, email, mobileNumber, redirectUrl, country, loginType} = globalState

    const params: PasswordlessStartParams = {
      client_id: clientId,
      client_secret: clientSecrets[clientId],
      mobile_number: loginType === LoginType.PHONE ? mobileNumber : null,
      email: loginType === LoginType.EMAIL ? email : null,
      redirect_uri: redirectUrl.href,
      country_code: country,
      channel: loginType === LoginType.EMAIL ? Channel.EMAIL : Channel.SMS,
      product: clientId,
      request_locale: getLocale(),
      reset_password: true,
      type: 'code'
    }

    setResetPasswordLoading(true)

    const result = await passwordlessStart(api)(params)

    setResetPasswordLoading(false)

    if (result.type === 'error') {
      console.error(result.error)

      trackEvent('authError', {
        product: 'authenticator',
        date: new Date().toISOString(),
        errorCode: result.error.errorCode,
        component: 'UserLocked.tsx',
        endpoint: '/passwordless/start'
      })

      // FIXME ask BE to provide error code for this
      if (result.error.message.includes('wait')) {
        enqueueSnackbar(t('authenticator.signIn.error.quickCodeTooSoon', {variant: 'error'}))
      } else {
        enqueueSnackbar(t('authenticator.signIn.error.unknown', {variant: 'error'}))
      }
    }
    if (result.type === 'value') {
      setGlobalState((g) => ({...g, passwordlessStartToken: result.value.start_token}))
      history.push(routes.ResetPassword)
    }
  }

  const resetPasswordPasswordLegacy = () => {
    history.push(routes.ResetPasswordLegacy)
  }

  const resetPassword = async () => {
    globalState.isPasswordlessSignInEnabled
      ? void resetPasswordPasswordless()
      : resetPasswordPasswordLegacy()
  }

  return (
    <form
      className={classNames(classes.changePasswordSuccessContainer)}
      data-test-id="page-signin-password"
      onSubmit={(e) => e.preventDefault()}
    >
      <Grid
        component="div"
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography
            variant="body1"
            className={classes.usernameText}
            data-test-id="authenticator-identifier"
          >
            {globalState.username}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            data-test-id="authenticator-change-identifier"
            variant="outlined"
            className={classNames(
              classes.linkButton,
              isCarbonBank ? classes.cbEnabledLink : classes.enabledLink
            )}
            color="primary"
            onClick={() => {
              onChangeUsername()
            }}
          >
            {t('authenticator.password.changeUsername')}
          </Button>
        </Grid>
      </Grid>
      <Alert
        severity="error"
        icon={
          <NotInterested
            fontSize="large"
            htmlColor="rgb(102, 31, 0)"
            style={{transform: 'scale(-1, 1)'}}
          />
        }
      >
        <AlertTitle>{t('authenticator.errorMessages.401_ACCOUNT_LOCKED')}</AlertTitle>
        {t('authenticator.errorMessages.401_ACCOUNT_LOCKED_HINT')}
      </Alert>
      <Link
        underline="hover"
        className={isCarbonBank ? classes.cbEnabledLink : classes.enabledLink}
        onClick={() => {
          if (!isResetPasswordLoading) {
            return resetPassword()
          }
        }}
      >
        {t('authenticator.password.resetNow')}
        {isResetPasswordLoading && <HCLinearProgress data-test-id="hc-linear-progress-bar" />}
      </Link>

      <LoadingButton
        disabled={true}
        fullWidth
        type="submit"
        color="primary"
        variant="outlined"
        loading={false}
        onClick={() => {}}
        data-test-id="authenticator-submit-password"
      >
        {t('authenticator.password.signin')}
      </LoadingButton>
    </form>
  )
}
