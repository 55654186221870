import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  inputStyle: {
    '& .MuiInputBase-root': {
      padding: 0,
      borderRadius: '4px',
      backgroundColor: '#FBFBFC',
      border: '1.5px solid rgba(0, 39, 77, 0.15)',
      width: '28px',
      height: '48px',
      fontWeight: 600,
      fontSize: '22px'
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderStyle: 'none'
    },
    '.MuiOutlinedInput-input': {
      padding: 0,
      textAlign: 'center'
    }
  }
}))
