import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  textFieldUnderline: {
    fontWeight: 500,
    fontSize: '14px',
    '&:before': {
      borderBottom: `1px solid ${theme.palette.secondary.light}`
    },
    '&:after': {
      borderBottom: `1px solid ${theme.palette.secondary.light}`
    },
    '&:hover:before': {
      borderBottom: `1px solid ${theme.palette.secondary.main} !important`
    }
  },
  textFieldDisabled: {},
  textFieldError: {},
  formError: {},
  submitButton: {
    padding: '13px 16px',
    width: '100%',
    textTransform: 'none',
    border: '1px solid #016AD4',
    borderRadius: '6px',
    backgroundColor: '#016AD4',
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.08)',
    color: '#FFFFFF',
    '&.Mui-disabled': {
      color: '#FFFFFF',
      opacity: 0.3,
      border: '1px solid rgba(1, 106, 212, 1)'
    },
    '&:hover': {
      backgroundColor: 'rgba(1, 106, 212, 1)',
      opacity: 0.3
    }
  },
  cbSubmitButton: {
    padding: '13px 16px',
    width: '100%',
    textTransform: 'none',
    border: '1px solid rgba(0, 120, 55, 1)',
    borderRadius: '6px',
    backgroundColor: 'rgba(0, 120, 55, 1)',
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.08)',
    color: '#FFFFFF',
    '&.Mui-disabled': {
      color: '#FFFFFF',
      opacity: 0.3,
      border: '1px solid rgba(1, 106, 212, 1)'
    },
    '&:hover': {
      backgroundColor: 'rgba(1, 106, 212, 1)',
      opacity: 0.3
    }
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  checkboxLabel: {
    color: theme.palette.secondary.main,
    fontWeight: 400
  },
  colorTextSecondary: {
    fontWeight: 400
  },
  clearFix: {
    paddingLeft: 10
  },
  routerLink: {
    '&:link': {
      textDecoration: 'none'
    }
  },
  radioForm: {
    fontFamily: 'Roboto',
    fontSize: '0.9em',
    color: theme.palette.text.primary,
    borderRadius: `${theme.spacing(4)}px`,
    border: `1px solid ${theme.palette.secondary.main}`,
    paddingRight: '16px',
    cursor: 'pointer'
  },
  radioButton: {
    border: 'none',
    borderRadius: 'none',
    boxShadow: 'none'
  },
  title: {
    marginBottom: `${theme.spacing(8)}px`
  },
  titleText: {
    fontWeight: 500
  },
  radioFormGroup: {
    marginBottom: `${theme.spacing(3)}px`,

    '& .MuiIconButton-root': {
      border: 'none',
      borderRadius: 'none',
      boxShadow: 'none'
    }
  },
  hidden: {
    width: 0,
    height: 0,
    overflow: 'hidden',
    opacity: 0,
    pointerEvents: 'none'
  },
  signIn: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },

  textInput: {
    marginTop: `${theme.spacing(1)}px`,
    color: 'blue'
  },
  passwordRoot: {
    width: '100%'
  },
  changePasswordSuccessContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  formControl: {
    display: 'block'
  },
  textFieldLabel: {
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(0.1)
  },
  usernameText: {
    fontSize: 16,
    // @ts-expect-error FIXME
    color: theme.palette.secondary.default
  },
  changeEmailText: {
    fontSize: 16
  },
  showPassword: {
    padding: 0,
    marginRight: theme.spacing(),
    boxShadow: 'none',
    border: 'none',
    transition: 'none',
    '&:hover': {
      border: 'none',
      boxShadow: 'none'
    },
    '&:active': {
      border: 'none',
      boxShadow: 'none'
    }
  },
  showPasswordLabel: {
    fontSize: 14
  },
  visiblePassword: {
    whiteSpace: 'pre-wrap',
    fontWeight: 'bold',
    fontSize: 16
  },
  forgotPasswordLink: {
    cursor: 'pointer'
  },
  resetNowLink: {
    cursor: 'pointer',
    fontSize: '1rem',
    display: 'block',
    padding: '12px 0'
  },
  inputField: {
    marginTop: '32px',
    padding: 0,
    marginBottom: '20px !important',
    width: '100%'
  },
  otpContainer: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    width: '100%',
    marginTop: '16px',
    marginBottom: '24px'
  },
  otpInput: {
    fontSize: '7ch',
    width: '9ch',
    margin: '16px 0',
    letterSpacing: '.5ch',
    border: 0,
    background: ` repeating-linear-gradient(90deg,
        dimgrey 0,
        dimgrey 1ch,
        transparent 0,
        transparent 1.5ch)
      0 100%/100% 2px no-repeat`,
    height: '1.3em',
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    }
  },
  otpError: {
    color: 'red',
    textAlign: 'center'
  },
  sendEmailLink: {marginTop: '48px'},
  TabsRoot: {},
  linkButton: {
    display: 'block',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 500,
    outline: 'none',
    border: 'none',
    '&:hover': {
      border: 'none'
    }
  },
  enabledLink: {cursor: 'pointer', color: 'rgba(1, 106, 212, 1)'},
  disabledLink: {
    pointerEvents: 'none',
    color: 'rgba(1, 106, 212, 1)',
    opacity: 0.3
  },
  disabledSendCodeAgain: {
    pointerEvents: 'none',
    color: 'rgba(1, 106, 212, 1)',
    [theme.breakpoints.down('md')]: {
      textAlign: 'start',
      padding: 0
    }
  },
  cbEnabledLink: {cursor: 'pointer', color: 'rgba(0, 120, 55, 1)'},
  cbDisabledLink: {
    pointerEvents: 'none',
    color: 'rgba(0, 120, 55, 1)',
    opacity: 0.3
  },
  cbDisabledSendCodeAgain: {
    pointerEvents: 'none',
    color: 'rgba(0, 120, 55, 1)',
    [theme.breakpoints.down('md')]: {
      textAlign: 'start',
      padding: 0
    }
  },
  TabRoot: {
    // color: theme.palette.text.secondary,
    // borderColor: theme.palette.text.secondary,
    borderBottom: '0.01rem solid',
    textTransform: 'none',
    borderBottomColor: theme.palette.secondary.light,
    '&:not($selected)': {
      color: theme.palette.text.primary
    },
    minWidth: '100px'
  },
  verifyDataExplanation: {whiteSpace: 'pre-wrap'},
  skipWrapper: {textAlign: 'center'},
  buttonSeparator: {
    textAlign: 'center',
    marginBottom: '20px'
  },
  recoveryCode: {
    outline: 'none',
    marginTop: '40px',
    '& .MuiFilledInput-input': {
      backgroundColor: '#FFFFFF',
      border: '1.5px solid rgba(0, 39, 77, 0.15)',
      borderRadius: '4px',
      boxShadow: 'inset 0px 4px 4px rgba(31, 73, 94, 0.08)',
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    },
    '& .Mui-focused': {
      border: 'none !important'
    }
  },
  tabContainer: {
    minHeight: 0,
    borderRadius: '6px',
    border: '1px solid rgba(0, 39, 77, 0.15)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#00274D',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  firstTab: {
    textTransform: 'none',
    padding: '7.5px 19px',
    margin: '3.5px',
    minHeight: 0,
    height: '35px',
    letterSpacing: 'normal',
    width: '48%',
    [theme.breakpoints.down('sm')]: {
      width: '49%'
    }
  },
  secondTab: {
    textTransform: 'none',
    padding: '7.5px 33px',
    margin: '3.5px',
    minHeight: 0,
    width: '48%',
    height: '35px',
    letterSpacing: 'normal',
    [theme.breakpoints.down('sm')]: {
      width: '49%'
    }
  },
  tabSelected: {
    background: '#016AD4',
    color: 'white',
    borderRadius: '4px',
    fontSize: '14px',
    lineHeight: '19px',
    fontWeight: 600
  },
  registrationTypeContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '12px',
    marginTop: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginTop: '44px'
    }
  }
}))
