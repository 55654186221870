import {Content, EnvAppInfoContainer} from '@hconnect/uikit'
import React from 'react'

const Version: React.FC = () => (
  <Content style={{textAlign: 'center'}}>
    <EnvAppInfoContainer />
  </Content>
)

export default Version
