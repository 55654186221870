import {Channel, passwordlessStart, passwordlessVerify} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {LoadingButton} from '@hconnect/uikit'
import {LinearProgress} from '@mui/material'
import {AxiosError} from 'axios'
import {isNumber} from 'lodash'
import queryString from 'query-string'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {makeStyles} from 'tss-react/mui'

import {api} from '../../api/api'
import {PaperTitle} from '../../Components/PaperTitle'
import {clientSecrets} from '../../constants'
import {useGlobalState} from '../../hooks/useGlobalState'
import {routes} from '../../routes'
import {HistoryType} from '../../types'
import {getLocale} from '../../utils'

const useStyles = makeStyles()(() => ({
  loginButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  errorText: {
    color: '#f44336',
    fontSize: '14px',
    fontWeight: 500
  },
  errorDescription: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  }
}))

const BAD_REQUEST_STATUSES = new Set([400, 401])

export const StepEmailRedirect: React.FC = () => {
  const {t} = useTranslation()
  const history = useHistory<HistoryType>()
  const [errorMessage, setErrorMessage] = useState<string | null>()
  const [errorDescription, setErrorDescription] = useState<string | null>()
  const [verified, setVerified] = useState(false)
  const {globalState, setGlobalState} = useGlobalState()
  const [loading, setLoading] = useState(false)
  const [canRetry, setCanRetry] = useState(false)
  const [retryFailed, setRetryFailed] = useState(false)
  const {classes} = useStyles()

  useEffect(() => {
    const verify = async () => {
      const {search} = window.location

      setCanRetry(
        Boolean(
          globalState.clientId &&
            globalState.email &&
            globalState.channel &&
            globalState.redirectUrl
        )
      )

      try {
        const response = await passwordlessVerify(api)({
          client_id: globalState.clientId,
          client_secret: clientSecrets[globalState.clientId],
          channel: globalState.channel,
          username: globalState.email,
          start_token: globalState.passwordlessStartToken,
          otp: globalState.otp,
          scope: globalState.scope
        })

        const token = response.data

        setGlobalState((g) => ({
          ...g,
          token
        }))

        if (globalState.pwlessPasswordReset) {
          return history.push(routes.ChangePassword)
        }

        history.push({
          pathname: routes.RequestUserDataVerification,
          search
        })
      } catch (error) {
        const e = error as AxiosError

        console.error(e)
        trackEvent('authError', {
          product: 'authenticator',
          date: new Date().toISOString(),
          errorCode: e.response?.status,
          component: 'StepEmailRedirect.tsx',
          endpoint: e.response?.config.url
        })

        const errorCode = e.response?.status

        if (isNumber(errorCode) && BAD_REQUEST_STATUSES.has(errorCode)) {
          setErrorMessage(t('authenticator.signIn.invalidRequest'))
          setErrorDescription(t('authenticator.signIn.invalidRequestDescription'))
        }

        if (errorCode === 403) {
          history.push({
            pathname: routes.RequestAccess,
            state: {accountNotFound: true},
            search
          })
          return
        }
      }
    }

    if (!verified) {
      verify().catch(() => {
        setErrorMessage(t('authenticator.signIn.invalidRequest'))
        setErrorDescription(t('authenticator.signIn.invalidRequestDescription'))
      })
      setVerified(true)
    }
  }, [history, t, setGlobalState, globalState, verified, setCanRetry])

  if (!errorMessage) {
    return (
      <>
        <LinearProgress />
      </>
    )
  }

  const handleSignInButton = () => {
    history.push({
      pathname: routes.SignIn,
      search: queryString.stringify({
        client_id: globalState.clientId,
        scope: globalState.scope,
        redirect_uri: globalState.redirectUrl.href,
        state: globalState.state
      })
    })
  }

  const onEmailPasswordlessSignInSubmit = async () => {
    try {
      const {clientId, email, redirectUrl, country} = globalState

      setLoading(true)

      const response = await passwordlessStart(api)({
        client_id: clientId,
        client_secret: clientSecrets[clientId],
        mobile_number: null,
        email,
        redirect_uri: redirectUrl.href,
        country_code: country,
        channel: Channel.EMAIL,
        product: clientId,
        request_locale: getLocale(),
        type: 'code'
      })

      setLoading(false)

      if (response.type === 'value') {
        history.push(routes.CheckMail)
        return
      }
    } catch (error) {
      const e = error as AxiosError

      console.error(e)
      trackEvent('authError', {
        product: 'authenticator',
        date: new Date().toISOString(),
        errorCode: e.response?.status,
        component: 'StepEmailRedirect.tsx',
        endpoint: e.response?.config.url
      })
      setLoading(false)
      console.error(error)
    }
    setRetryFailed(true)
  }

  const InvalidRequestButton = () => {
    if (canRetry) {
      return (
        <LoadingButton
          loading={loading}
          data-test-id="resend-quick-link"
          type="submit"
          color="primary"
          variant="outlined"
          disabled={loading}
          onClick={onEmailPasswordlessSignInSubmit}
        >
          {t('authenticator.signIn.resendQuickLink')}
        </LoadingButton>
      )
    }

    return (
      <LoadingButton
        data-test-id="navigate-to-signIn-page"
        type="submit"
        color="primary"
        variant="outlined"
        onClick={handleSignInButton}
      >
        {t('authenticator.signIn.signIn')}
      </LoadingButton>
    )
  }

  return (
    <>
      <PaperTitle title="" subtitle={errorMessage} isCentered />
      <div className={classes.errorDescription}>{errorDescription}</div>
      <br />
      <div className={classes.loginButton}>
        <InvalidRequestButton />

        {!loading && retryFailed && (
          <div className={classes.errorText}>
            <br />
            {t('authenticator.signIn.error.cannotRetryQuickLink')}
          </div>
        )}
      </div>
    </>
  )
}
