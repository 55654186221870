import {Box, Link} from '@mui/material'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {useHistory} from 'react-router'

import {PaperTitle} from '../../Components/PaperTitle'
import {LoginType} from '../../constants'
import {useGlobalState} from '../../hooks/useGlobalState'
import {routes} from '../../routes'

export const RussianRequestAccessPage = () => {
  const {t} = useTranslation()
  const history = useHistory()
  const {globalState} = useGlobalState()
  const alternativeLoginType =
    globalState.loginType === LoginType.EMAIL ? 'phoneNumber' : 'emailAddress'
  const alternativeLoginTypeLocalizationKey = t(
    `authenticator.requestAccess.${alternativeLoginType}`
  )

  return (
    <Box>
      <PaperTitle
        title={t('authenticator.requestAccess.titleAccountNotFound')}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore type incorrectly doesn't allow React Element as subtitle
        subtitle={
          <Trans
            t={t}
            i18nKey="authenticator.requestAccess.subtitleAccountNotFoundRU"
            alternativeLoginType={alternativeLoginTypeLocalizationKey}
          >
            Are you registered with your {alternativeLoginTypeLocalizationKey} instead? Then try to
            <Link
              style={{cursor: 'pointer'}}
              onClick={() => {
                history.push({pathname: routes.SignIn, search: window.location.search})
              }}
            >
              sign in
            </Link>
            again. Or create an account by following this
            <Link
              style={{cursor: 'pointer'}}
              href="https://forms.yandex.ru/u/5e612024c6692c0a4826378c/"
              referrerPolicy="no-referrer"
              target="_blank"
            >
              link
            </Link>
            .
          </Trans>
        }
        isCentered={false}
      />
    </Box>
  )
}
