import {Typography} from '@hconnect/uikit'
import React from 'react'
import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  title: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
    marginBottom: '24px',
    gap: '12px'
  },
  centeredTitle: {
    marginBottom: `${theme.spacing(1)}px`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  titleText: {
    fontSize: '20px',
    fontWeight: 600,
    width: '100%',
    color: 'rgba(78, 86, 94, 1)',
    textTransform: 'none'
  },
  subTitleText: {
    fontSize: '16px',
    fontWeight: 400,
    color: theme.palette.text.primary
  }
}))

type PaperTitleProps = {
  isCentered: boolean
  title?: string
  subtitle?: string
}
export const PaperTitle = ({title, subtitle, isCentered}: PaperTitleProps) => {
  const {classes} = useStyles()
  return (
    <div className={isCentered ? classes.centeredTitle : classes.title}>
      <Typography
        variant="h2"
        align={!isCentered ? 'left' : 'center'}
        classes={{h2: classes.titleText}}
        data-test-id="authenticator-form-title"
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography
          variant="h3"
          align={!isCentered ? 'left' : 'center'}
          classes={{h3: classes.subTitleText}}
          data-test-id="authenticator-form-subtitle"
        >
          {subtitle}
        </Typography>
      )}
    </div>
  )
}
