import {
  Channel,
  passwordlessStart,
  PasswordlessStartParams,
  passwordlessVerify,
  Product
} from '@hconnect/apiclient'
import {getBrandingFromUrl} from '@hconnect/common/branding/Branding.utils'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {Link} from '@hconnect/uikit'
import {InputMessage} from '@hconnect/uikit/src/lib2'
import {Box, Button, CircularProgress, TextField} from '@mui/material'
import {AxiosError} from 'axios'
import classNames from 'classnames'
import {useSnackbar} from 'notistack'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useLocation} from 'react-router-dom'

import {api} from '../../api/api'
import {loginForAuthCode, loginTwoStep} from '../../api/user'
import {CodeInput} from '../../Components/CodeInput/CodeInput'
import LoadingButton from '../../Components/LoadingButton'
import {PaperTitle} from '../../Components/PaperTitle'
import {UsernameGroup} from '../../Components/UsernameGroup/UsernameGroup'
import {clientSecrets, LoginType} from '../../constants'
import {useGlobalState} from '../../hooks/useGlobalState'
import {routes} from '../../routes'
import {HistoryType} from '../../types'
import {getLocale} from '../../utils'

import {useStyles} from './SignIn.styles'
import {useOTPCode} from './useOTPCode'
import {formatIdentityServerReturnUrl} from './utils/formatIdentityServerReturnUrl'

const OTP_LENGTH = 6
const SMS_SEND_TIMEOUT = 60
const SMS_TIMEOUT_CHECK = 1

const RESPONSE_UNAUTHORIZED = 401
const RESPONSE_FORBIDDEN = 403

// eslint-disable-next-line complexity
export const StepInsertCode: React.FC = () => {
  const {t} = useTranslation()
  const history = useHistory<HistoryType>()
  const location = useLocation<{
    tfaType?: 'none' | 'phoneNumber' | 'authenticatorApp' | null
    tfaFlow?: 'quickCode' | 'password'
    otp?: string
  }>()
  const {classes} = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  const {globalState, setGlobalState} = useGlobalState()

  const [otp, setOtp] = useState('')
  const [counterToResend, setCounterToResend] = useState(SMS_SEND_TIMEOUT)
  const [retryButtonDisabled, setRetryButtonDisabled] = useState(true)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [recoveryCodeMode, setRecoveryCodeMode] = useState(false)
  const [isLoadingSubmit, setLoadingSubmit] = useState(false)
  const [isLoadingResend, setLoadingResend] = useState(false)
  const isCarbonBank = globalState.clientId === Product.CarbonBank

  const isResetPwScreen = location.pathname === routes.ResetPassword

  const {
    clientId,
    mobileNumber,
    email,
    passwordlessStartToken,
    scope,
    loginType,
    identityServerReturnUrl,
    password,
    username: emailOrMobile
  } = globalState

  useEffect(() => {
    if (location.state?.otp) {
      setOtp('')
    }
  }, [location.state?.otp])

  const autoComplete = 'one-time-code'

  const isLoginTypeEmail = loginType === LoginType.EMAIL

  const linkClass = retryButtonDisabled
    ? isCarbonBank
      ? classes.cbDisabledSendCodeAgain
      : classes.disabledSendCodeAgain
    : isCarbonBank
      ? classes.cbEnabledLink
      : classes.enabledLink

  const linkText = retryButtonDisabled
    ? isLoginTypeEmail
      ? t('authenticator.signIn.sendEmailAgainInSeconds', {counter: counterToResend})
      : t('authenticator.signIn.sendSmsAgainInSeconds', {counter: counterToResend})
    : isLoginTypeEmail
      ? t('authenticator.signIn.sendCodeAgain')
      : t('authenticator.signIn.sendSmsAgain')

  const channel = loginType === LoginType.EMAIL ? Channel.EMAIL : Channel.SMS

  const username = loginType === LoginType.EMAIL ? email : emailOrMobile

  useEffect(() => {
    const id = setInterval(() => {
      if (counterToResend === 0) {
        setRetryButtonDisabled(false)
      } else {
        setCounterToResend(counterToResend - 1)
      }
    }, SMS_TIMEOUT_CHECK * 1000)

    return () => clearInterval(id)
  }, [retryButtonDisabled, counterToResend])

  // eslint-disable-next-line complexity
  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      setLoadingSubmit(true)

      if (identityServerReturnUrl) {
        if (!location.state?.tfaType || location.state?.tfaType === 'none') {
          const validIdentityServerUrl = formatIdentityServerReturnUrl(
            identityServerReturnUrl instanceof URL
              ? identityServerReturnUrl
              : new URL(identityServerReturnUrl)
          )
          const authCodeResponse = await loginForAuthCode(api)(
            username,
            otp,
            validIdentityServerUrl
              ? validIdentityServerUrl.toString()
              : identityServerReturnUrl.toString(),
            true,
            channel,
            passwordlessStartToken || ''
          )

          if (!authCodeResponse.tfaType || authCodeResponse.tfaType === 'none') {
            setGlobalState((g) => ({
              ...g,
              identityServerReturnUrl: authCodeResponse.idsReturnUrl,
              pwlessPasswordReset: authCodeResponse.isPasswordChangeRequired
            }))

            authCodeResponse.isPasswordChangeRequired
              ? history.push(routes.ChangePassword)
              : history.push(routes.RequestUserDataVerification)
          }

          if (authCodeResponse.tfaType && authCodeResponse.tfaType !== 'none') {
            history.push(routes.InsertQuickCode, {
              tfaType: authCodeResponse.tfaType,
              tfaFlow: 'quickCode',
              otp: otp
            })
            return
          }
        }

        if (location.state?.tfaType && location.state?.tfaType !== 'none') {
          const validIdentityServerUrl = formatIdentityServerReturnUrl(
            identityServerReturnUrl instanceof URL
              ? identityServerReturnUrl
              : new URL(identityServerReturnUrl)
          )

          const authCodeResponse = await loginTwoStep(api)(
            username,
            location.state?.otp || password,
            validIdentityServerUrl
              ? validIdentityServerUrl.toString()
              : identityServerReturnUrl.toString(),
            !!(location.state?.tfaFlow && location.state?.tfaFlow === 'quickCode'),
            channel,
            null,
            otp,
            recoveryCodeMode
          )
          if (authCodeResponse) {
            setGlobalState((g) => ({
              ...g,
              identityServerReturnUrl: authCodeResponse.idsReturnUrl,
              pwlessPasswordReset: authCodeResponse.isPasswordChangeRequired
            }))

            authCodeResponse.isPasswordChangeRequired
              ? history.push(routes.ChangePassword)
              : history.push(routes.RequestUserDataVerification)
          }
        }
      } else {
        const response = await passwordlessVerify(api)({
          client_id: clientId,
          client_secret: clientSecrets[clientId],
          channel,
          username,
          start_token: passwordlessStartToken || '',
          otp,
          scope
        })

        if (response.data) {
          const token = response.data

          setGlobalState((g) => ({
            ...g,
            token,
            pwlessPasswordReset: isResetPwScreen ? true : false
          }))

          isResetPwScreen
            ? history.push(routes.ChangePassword)
            : history.push(routes.RequestUserDataVerification)
        }
      }
    } catch (error) {
      const e = error as AxiosError<{type?: string}>

      console.error(e)
      trackEvent('authError', {
        product: 'authenticator',
        date: new Date().toISOString(),
        errorCode: e.response?.status,
        component: 'StepInsertCode.tsx',
        endpoint: e.response?.config.url
      })
      if (e.response?.status === RESPONSE_UNAUTHORIZED) {
        if (
          e.response.data?.type === 'https://api.hce.heidelbergcement.com/errors/account-locked'
        ) {
          history.push(routes.UserLocked)
          return
        }
        recoveryCodeMode
          ? setErrorMessage(t('authenticator.signIn.error.recoveryCodeVerification'))
          : setErrorMessage(t('authenticator.signIn.error.quickCodeVerification'))
        return
      }

      // no account yet
      if (e.response?.status === RESPONSE_FORBIDDEN) {
        const {redirectUrl} = globalState
        const branding = getBrandingFromUrl(redirectUrl)
        if (branding.country === 'RU') {
          history.push(routes.RussianRequestAccessPage)
        } else {
          history.push(routes.RequestAccess, {accountNotFound: true})
        }
        return
      }

      setErrorMessage(t('authenticator.signIn.error.unknown'))
    } finally {
      setLoadingSubmit(false)
    }
  }

  const handleSendCodeAgain = async () => {
    const {clientId, email, redirectUrl, country} = globalState

    const isMobile = loginType === LoginType.PHONE

    const params: PasswordlessStartParams = {
      client_id: clientId,
      client_secret: clientSecrets[clientId],
      mobile_number: isMobile ? emailOrMobile : null,
      email: isMobile ? null : email,
      redirect_uri: redirectUrl.href,
      country_code: country,
      channel: isMobile ? Channel.SMS : Channel.EMAIL,
      product: clientId,
      request_locale: getLocale(),
      reset_password: isResetPwScreen ? true : undefined,
      type: 'code'
    }

    setLoadingResend(true)
    const response = await passwordlessStart(api)(params)
    setLoadingResend(false)

    if (response.type === 'value') {
      setGlobalState((g) => ({
        ...g,
        passwordlessStartToken: response.value.start_token
      }))
      setRetryButtonDisabled(true)
      setCounterToResend(SMS_SEND_TIMEOUT)

      isMobile
        ? enqueueSnackbar(t('authenticator.signIn.smsSentAgain', {variant: 'success'}))
        : enqueueSnackbar(t('authenticator.signIn.emailSentAgain', {variant: 'success'}))
    } else {
      trackEvent('authError', {
        product: 'authenticator',
        date: new Date().toISOString(),
        errorCode: response.error.errorCode,
        component: 'StepInsertCode.tsx',
        endpoint: '/passwordless/start'
      })
      enqueueSnackbar(t('authenticator.signIn.error.unknown', {variant: 'error'}))
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!recoveryCodeMode && e.target.value.length > OTP_LENGTH) {
      return
    }

    setOtp(e.target.value)
  }

  const getResetPwSubtitle = () =>
    isLoginTypeEmail
      ? t('authenticator.passwordReset.email.subtitleCode', {email})
      : t('authenticator.passwordReset.phone.subtitle', {mobile: mobileNumber})

  const getLoginSubtitle = () => {
    if (location.state?.tfaType === 'authenticatorApp' && !recoveryCodeMode) {
      return t('authenticator.signIn.tfa.authenticatorCode')
    } else if (location.state?.tfaType === 'phoneNumber' && !recoveryCodeMode) {
      return t('authenticator.signIn.tfa.phoneNumberCode')
    } else if (recoveryCodeMode) {
      return t('authenticator.signIn.recoveryCode.description')
    } else {
      return isLoginTypeEmail
        ? t('authenticator.signIn.emailSent', {email})
        : t('authenticator.signIn.smsSent', {mobileNumber})
    }
  }

  const getSubtitle = (): string => (isResetPwScreen ? getResetPwSubtitle() : getLoginSubtitle())

  const {
    activeInput,
    otpValue,
    handleOnFocus,
    handleOnChange,
    handleOnInput,
    handleOnKeyDown,
    handleOnPaste
  } = useOTPCode(otp, setOtp, OTP_LENGTH)

  return (
    <>
      <PaperTitle
        title={
          location.state?.tfaType && location.state?.tfaType !== 'none' && !recoveryCodeMode
            ? t('authenticator.signIn.tfa.title')
            : recoveryCodeMode
              ? t('authenticator.signIn.recoveryCode.label')
              : isResetPwScreen
                ? t('authenticator.passwordReset.phone.title')
                : t('authenticator.signIn.insertQuickCodeTitle')
        }
        isCentered={false}
      />
      <Box style={{marginBottom: '24px'}}>{getSubtitle()}</Box>
      <UsernameGroup
        isLoading={isLoadingSubmit || isLoadingResend}
        error={errorMessage}
        setError={setErrorMessage}
      />

      <form onSubmit={(e) => e.preventDefault()}>
        <div className={classes.otpContainer}>
          {recoveryCodeMode ? (
            <TextField
              variant="filled"
              data-test-id="recoveryCode-code-input"
              label={t('authenticator.signIn.recoveryCode.label')}
              className={classes.recoveryCode}
              InputProps={{disableUnderline: true}}
              InputLabelProps={{focused: false, style: {color: '#757575'}}}
              fullWidth={true}
              focused={true}
              onChange={handleInputChange}
            />
          ) : (
            <Box style={{display: 'flex', gap: '12px'}}>
              {Array.from({length: OTP_LENGTH}).map((_, index) => {
                return (
                  <CodeInput
                    key={index}
                    shouldFocus={activeInput === index}
                    value={otpValue()[index] || ''}
                    onChange={handleOnChange}
                    data-test-id={`authenticator-phone-password-input-${index}`}
                    autoFocus={true}
                    autoComplete={autoComplete}
                    onKeyDown={handleOnKeyDown}
                    onPaste={(event) => handleOnPaste(event)}
                    onInput={(event: React.FormEvent<HTMLInputElement>) => handleOnInput(event)}
                    handleOnFocus={(event) => handleOnFocus(event, index)}
                  />
                )
              })}
            </Box>
          )}
        </div>
        {errorMessage && <InputMessage message={errorMessage} />}

        <LoadingButton
          data-test-id={
            isResetPwScreen
              ? 'authenticator-phone-password-reset-button'
              : 'authenticator-phone-password-button'
          }
          fullWidth
          type="submit"
          color="primary"
          variant="outlined"
          disabled={!recoveryCodeMode && otp.length !== OTP_LENGTH}
          loading={isLoadingSubmit}
          onClick={handleSubmit}
          style={{marginTop: errorMessage ? '16px' : undefined}}
        >
          {isResetPwScreen
            ? t('authenticator.passwordReset.phone.primaryButton')
            : t('authenticator.signIn.insertQuickCodeButton')}
        </LoadingButton>
      </form>

      <Box
        style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '16px'}}
      >
        {isLoadingResend ? (
          <CircularProgress size={20} />
        ) : (
          (location.state?.tfaType === 'none' || !location.state?.tfaType) &&
          !recoveryCodeMode && (
            <Button
              variant="outlined"
              className={classNames(classes.linkButton, linkClass)}
              onClick={handleSendCodeAgain}
            >
              {linkText}
            </Button>
          )
        )}
      </Box>
      <Box style={{marginTop: '44px'}}>
        {location.state?.tfaType === 'authenticatorApp' && (
          <Link
            variant="body2"
            underline="always"
            className={classNames(
              classes.linkButton,
              isCarbonBank ? classes.cbEnabledLink : classes.enabledLink
            )}
            onClick={() => setRecoveryCodeMode(!recoveryCodeMode)}
            data-test-id={recoveryCodeMode ? 'tfa-code-link' : 'recovery-code-link'}
          >
            {recoveryCodeMode
              ? t('authenticator.signIn.useTfaCodeInstead')
              : t('authenticator.signIn.useRecoveryCodeInstead')}
          </Link>
        )}
        {!isResetPwScreen &&
          globalState.isPasswordfulSignInEnabled &&
          (!location.state?.tfaType || location.state?.tfaType === 'none') && (
            <Link
              variant="body2"
              underline="always"
              className={classNames(
                classes.linkButton,
                isCarbonBank ? classes.cbEnabledLink : classes.enabledLink
              )}
              onClick={() => history.push(routes.Password)}
            >
              {t('authenticator.signIn.usePasswordInstead')}
            </Link>
          )}
      </Box>
    </>
  )
}
