import {Cancel, CheckCircle} from '@mui/icons-material'
import {Box, Typography} from '@mui/material'
import classNames from 'classnames'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useTextStyles} from '../../App.styles'
import {
  hasAtLeastEightCharacters,
  hasAtLeastOneDigit,
  hasAtLeastOneNonAlphanumericCharacter,
  hasAtLeastOneUppercaseAndOneLowercaseLetter
} from '../../utils'

interface Props {
  isValid: boolean
  type: 'digit' | 'letter' | 'characters' | 'nonAlphanumericCharacter'
}

const PasswordValidationIcon = ({isValid, type}: Props) =>
  isValid ? (
    <CheckCircle
      style={{color: 'green'}}
      data-test-id={`password-validation-check-icon-for-${type}`}
    />
  ) : (
    <Cancel style={{color: 'grey'}} data-test-id={`password-validation-cancel-icon-for-${type}`} />
  )

export const PasswordValidation = ({newPassword1}: {newPassword1: string}) => {
  const {t} = useTranslation()
  const {classes: textClasses} = useTextStyles()
  return (
    <Box mb={0.5}>
      <Box display="flex" alignItems="center" mt={2}>
        <PasswordValidationIcon isValid={hasAtLeastOneDigit(newPassword1)} type="digit" />
        <Box ml={0.5}>
          <Typography
            className={classNames(textClasses.text, textClasses.small, textClasses.medium)}
          >
            {t('authenticator.changePassword.passwordPolicyOneDigit')}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" mt={1}>
        <PasswordValidationIcon
          isValid={hasAtLeastEightCharacters(newPassword1)}
          type="characters"
        />
        <Box ml={0.5}>
          <Typography
            className={classNames(textClasses.text, textClasses.small, textClasses.medium)}
          >
            {t('authenticator.changePassword.passwordPolicyEightCharacters')}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" mt={1}>
        <PasswordValidationIcon
          isValid={hasAtLeastOneNonAlphanumericCharacter(newPassword1)}
          type="nonAlphanumericCharacter"
        />
        <Box ml={0.5}>
          <Typography
            className={classNames(textClasses.text, textClasses.small, textClasses.medium)}
          >
            {t('authenticator.changePassword.passwordPolicyOneNonAlphanumericCharacter')}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" mt={1}>
        <PasswordValidationIcon
          isValid={hasAtLeastOneUppercaseAndOneLowercaseLetter(newPassword1)}
          type="letter"
        />
        <Box ml={0.5}>
          <Typography
            className={classNames(textClasses.text, textClasses.small, textClasses.medium)}
          >
            {t('authenticator.changePassword.passwordPolicyOneUppercaseAndOneLowercaseLetter')}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
