import {AxiosError} from 'axios'
import {InvalidTokenError} from 'jwt-decode'
import {get} from 'lodash'

import {AccountFormTypes, AccountFormValues} from './Components/AccountForm/types'
import {
  BackendFieldError,
  BackendValidationError,
  HttpStatusAndErrorTypeToUniqueKey,
  InternalErrors
} from './ErrorMap'

export const getErrorKeyFromAxiosError = (error) => {
  const {response} = error

  const {status, data} = response
  if (!data) {
    const errorKey = get(
      HttpStatusAndErrorTypeToUniqueKey,
      [status, 'DEFAULT'],
      'ERROR_STATUS_CODE_NOT_IMPLEMENTED'
    )
    return errorKey
  }
  const {type} = data
  if (!type) {
    const errorKey = get(
      HttpStatusAndErrorTypeToUniqueKey,
      [status, 'DEFAULT'],
      'ERROR_STATUS_CODE_NOT_IMPLEMENTED'
    )
    return errorKey
  }
  const errorKey = get(HttpStatusAndErrorTypeToUniqueKey, [status, type], 'BAD_API_RESPONSE')
  return errorKey
}

type ErrorInput = AxiosError | string | InvalidTokenError | DOMException

export const getErrorKey = (error: ErrorInput): string => {
  let errorKey = InternalErrors.UNKNOWN_ERROR
  if (typeof error === 'string') {
    errorKey = error
  } else if (typeof error === 'object') {
    if (error instanceof DOMException) {
      errorKey = error.message
    } else if (error instanceof InvalidTokenError) {
      errorKey = InternalErrors.BAD_TOKENINFO_ERROR
    } else if (error.response) {
      errorKey = getErrorKeyFromAxiosError(error)
    } else if (error.request) {
      errorKey = InternalErrors.NETWORK_ERROR
    }
  }

  return errorKey
}

const handleError = (
  errorKey: string,
  values: AccountFormValues,
  formMethods: AccountFormTypes
) => {
  if (errorKey === '403_USER_ALREADY_EXISTS') {
    const emailFilledOut = values.email.length > 0
    const mobileNumberFilledOut = values.mobileNumber.length > 0
    if (emailFilledOut) {
      formMethods.setError('email', {
        message: '403_USER_ALREADY_EXISTS'
      })
    }
    if (mobileNumberFilledOut) {
      formMethods.setError('mobileNumber', {
        message: '403_USER_ALREADY_EXISTS'
      })
    }
  } else if (errorKey === '403_PASSWORD_POLICY') {
    formMethods.setError('password', {
      message: '403_PASSWORD_POLICY'
    })
  } else {
    return errorKey
  }
}

export const handleValidationError = (
  error: any,
  formValues: AccountFormValues,
  formMethods: AccountFormTypes
) => {
  if (error?.response?.data?.type === BackendValidationError) {
    if (
      handleBackendFieldValidationError(
        error?.response?.data?.extendedData?.fieldErrors,
        formMethods
      )
    ) {
      return
    }
  }
  const generalErrorKey = getErrorKey(error)
  return handleError(generalErrorKey, formValues, formMethods)
}

export const handleBackendFieldValidationError = (
  fieldErrors: BackendFieldError[],
  formMethods: AccountFormTypes
) => {
  if (fieldErrors?.length > 0) {
    let handled = false
    fieldErrors?.forEach((fieldError: BackendFieldError) => {
      if (fieldError.fieldName.toLowerCase() === 'mobilenumber') {
        if (fieldError.validationErrorType.toLowerCase() === 'missing') {
          formMethods.setError('mobileNumber', {
            message: InternalErrors.PHONE_OR_EMAIL_REQUIRED
          })
        } else {
          formMethods.setError('mobileNumber', {
            message: InternalErrors.INVALID_MOBILE_NUMBER
          })
        }
        handled = true
      } else if (fieldError.fieldName.toLowerCase() === 'email') {
        if (fieldError.validationErrorType.toLowerCase() === 'missing') {
          formMethods.setError('email', {
            message: InternalErrors.PHONE_OR_EMAIL_REQUIRED
          })
          handled = true
        }
      }
    })

    return handled
  }
}
