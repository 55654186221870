import {Product} from '@hconnect/apiclient'
import queryString from 'query-string'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router'

import {PaperTitle} from '../Components/PaperTitle'
import {getCRMUrl} from '../utils'

type ErrorPageProps = {
  error?: string
}

export const ErrorPage = ({error}: ErrorPageProps) => {
  const {t} = useTranslation()
  const history = useHistory()

  const errorKey = history?.location?.pathname.split('/')[2]
  const isCRM = queryString.parse(window.location.search)?.client_id === Product.CRM

  isCRM && window.opener.postMessage('Error: Account not found', getCRMUrl())

  return (
    <div data-test-id="error-page">
      <PaperTitle
        isCentered={false}
        title={t('authenticator.errorPageTitle')}
        subtitle={error ? t('errorboundary.error') : t(`authenticator.errorMessages.${errorKey}`)}
      />
    </div>
  )
}
