import {makeStyles} from 'tss-react/mui'
export const useStyles = makeStyles()((theme) => ({
  paper: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      padding: `${theme.spacing(3.5)}px`,
      boxShadow: 'none'
    },
    [theme.breakpoints.up('sm')]: {
      width: '375px',
      height: '400px',
      padding: `${theme.spacing(4)}px`
    }
  },
  title: {
    color: 'red'
  },
  submitButton: {
    marginTop: theme.spacing(2),
    marginBottom: `${theme.spacing(3)}px`,
    display: 'block'
  },
  resetPasswordLoading: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  resetPasswordLoadingElementContent: {
    marginBottom: '1rem',
    flex: 1
  },
  retryLink: {
    cursor: 'pointer'
  },
  progressIndicator: {
    marginBottom: '0.2em'
  },
  bodyText: {
    whiteSpace: 'pre-line'
  }
}))
