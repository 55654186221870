import {messageTypeIcons} from '@hconnect/hub/src/Molecules/AnnouncementMessages/AnnouncementMessages'
import {Typography} from '@hconnect/uikit'
import {Box} from '@mui/material'
import classNames from 'classnames'
import React from 'react'

import {useAppStyles} from '../../App.styles'

export const AnnouncementMessage = () => {
  const {classes} = useAppStyles()

  return (
    <Box className={classNames(classes.announcementContainer, classes.announcementWarning)}>
      <Box className={classes.announcementRoot}>
        <Box className={classes.announcementContent}>
          {messageTypeIcons['warning']}
          <Typography variant="inherit">
            Due to ongoing technical difficulties accessing and using our applications is
            temporarily unavailable. You do not need to reset your password or account credentials.
            We are working on it and will announce when systems are fully functional again.
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
