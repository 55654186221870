import {Channel, passwordlessStart, PasswordlessStartParams, Product} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {Link} from '@hconnect/uikit'
import {Box, Button} from '@mui/material'
import {useSnackbar} from 'notistack'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {api} from '../../api/api'
import {PaperTitle} from '../../Components/PaperTitle'
import {clientSecrets} from '../../constants'
import {useGlobalState} from '../../hooks/useGlobalState'
import {getLocale} from '../../utils'

import {useStyles} from './SignIn.styles'

const SMS_SEND_TIMEOUT = 60
const SMS_TIMEOUT_CHECK = 1

export const StepCheckMail: React.FC = () => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  const {globalState, setGlobalState} = useGlobalState()

  const [counterToResend, setCounterToResend] = useState(SMS_SEND_TIMEOUT)
  const [resendButtonDisabled, setEmailButtonDisabled] = useState(true)
  const {clientId, email, redirectUrl, country} = globalState
  const isCarbonBank = clientId === Product.CarbonBank

  const handleSendEmailAgain = async () => {
    const params: PasswordlessStartParams = {
      client_id: clientId,
      client_secret: clientSecrets[clientId],
      mobile_number: null,
      email,
      redirect_uri: redirectUrl.href,
      country_code: country,
      channel: Channel.EMAIL,
      product: clientId,
      request_locale: getLocale(),
      type: 'code'
    }

    const response = await passwordlessStart(api)(params)
    if (response.type === 'value') {
      setGlobalState((g) => ({
        ...g,
        passwordlessStartToken: response.value.start_token
      }))
      setEmailButtonDisabled(true)
      setCounterToResend(SMS_SEND_TIMEOUT)

      enqueueSnackbar(t('authenticator.signIn.emailSentAgain', {variant: 'success'}))
    } else {
      trackEvent('authError', {
        product: 'authenticator',
        date: new Date().toISOString(),
        errorCode: response.error.errorCode,
        component: 'StepCheckMail.tsx',
        endpoint: '/passwordless/start'
      })

      enqueueSnackbar(t('authenticator.signIn.error.error', {variant: 'error'}))
    }
  }

  const linkClass = resendButtonDisabled
    ? isCarbonBank
      ? classes.cbDisabledSendCodeAgain
      : classes.disabledSendCodeAgain
    : isCarbonBank
    ? classes.cbEnabledLink
    : classes.enabledLink

  const linkText = resendButtonDisabled
    ? t('authenticator.signIn.sendEmailAgainInSeconds', {counter: counterToResend})
    : t('authenticator.signIn.sendEmailAgain')

  useEffect(() => {
    const id = setInterval(() => {
      if (counterToResend === 0) {
        setEmailButtonDisabled(false)
      } else {
        setCounterToResend(counterToResend - 1)
      }
    }, SMS_TIMEOUT_CHECK * 1000)

    return () => clearInterval(id)
  }, [resendButtonDisabled, counterToResend])

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
      }}
    >
      <PaperTitle
        title={t('authenticator.signIn.checkMail')}
        subtitle={t('authenticator.signIn.checkMailSubtitle', {email})}
        isCentered={false}
      />
      {resendButtonDisabled ? (
        <Link className={linkClass} onClick={handleSendEmailAgain}>
          {linkText}
        </Link>
      ) : (
        <Button
          className={isCarbonBank ? classes.cbSubmitButton : classes.submitButton}
          onClick={handleSendEmailAgain}
          variant="outlined"
          color="primary"
          size="large"
        >
          {linkText}
        </Button>
      )}
    </Box>
  )
}
