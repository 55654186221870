export const routes = {
  SignIn: '/',
  Account: '/account',
  Password: '/password',
  ChangePassword: '/change-password',
  ChangePasswordSuccess: '/change-password/success',
  ResetPassword: '/reset-password',
  ResetPasswordLegacy: '/reset-password-legacy',
  Register: '/register',
  RequestAccess: '/request-access',
  RequestAccessSuccess: '/request-access/success',
  CreateAccount: '/create-account',
  CreateAccountSuccess: '/create-account/success',
  InsertQuickCode: '/quickcode',
  CheckMail: '/checkmail',
  MailRedirect: '/passwordless',
  VerifyUserData: '/verify',
  RequestUserDataVerification: '/verifyData',
  UserLocked: '/locked',
  Error: '/error/:errorKey',
  RussianRequestAccessPage: '/russian-request-access-page'
}
