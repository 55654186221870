import {InputTextField} from '@hconnect/uikit/src/lib2'
import queryString from 'query-string'
import React, {ChangeEventHandler, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'

import {useGlobalState} from '../../hooks/useGlobalState'

import {AccountFormComponentProps, AccountFormTypes} from './types'
import {useAvailableCountries} from './useCountries'

interface Props extends AccountFormComponentProps {
  value: string
  onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  formMethods: AccountFormTypes
  disabled?: boolean
}

export const CountryField: React.FC<Props> = ({name, value, onChange, disabled, formMethods}) => {
  const availableCountries = useAvailableCountries()
  const {globalState} = useGlobalState()
  const {t} = useTranslation()
  const location = useLocation()
  const {icc} = queryString.parse(location.search)
  const [preselectedCountry, setPreselectedCountry] = useState(icc?.toString())
  const countrySelectValues = availableCountries?.map((country) => ({
    value: country.countryCode,
    label: t(`authenticator.requestAccess.countries.${country.countryCode}`)
  }))

  countrySelectValues?.sort((a, b) => (a.label < b.label ? -1 : 1))

  useEffect(() => {
    const foundCountry = countrySelectValues?.find((country) => country.value === icc)?.value

    setPreselectedCountry(foundCountry)

    foundCountry && preselectedCountry && formMethods.setValue('country', preselectedCountry)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preselectedCountry])

  return (
    <InputTextField
      id="countryField"
      name={name}
      value={preselectedCountry || value}
      onChange={onChange}
      error={!!formMethods.formState.errors.country}
      helperText={
        !!formMethods.formState.errors.country &&
        t(`authenticator.errorMessages.${formMethods.formState.errors.country?.message}`)
      }
      select
      label={t('authenticator.requestAccess.textfieldLabels.country')}
      disabled={disabled || !!preselectedCountry || !!globalState.country}
      selectValues={countrySelectValues || []}
      required
    />
  )
}
