import {trackEvent} from '@hconnect/common/logging/Analytics'
import {InputMessage, InputTextField} from '@hconnect/uikit/src/lib2'
import {Box} from '@mui/material'
import {AxiosError} from 'axios'
import {useSnackbar} from 'notistack'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {api, createAuthHttpClient} from '../../api/api'
import {changeUserPasswordForAuthCode} from '../../api/user'
import LoadingButton from '../../Components/LoadingButton'
import {PaperTitle} from '../../Components/PaperTitle'
import {getErrorKey} from '../../errorHandling'
import {useGlobalState} from '../../hooks/useGlobalState'
import {routes} from '../../routes'
import {isPasswordValid, mobileAndTabletCheck} from '../../utils'

import {PasswordValidation} from './PasswordValidation'
import {isFormError} from './SignIn'
import {useStyles} from './SignIn.styles'

export const StepChangePassword: React.FC = () => {
  const isMobile = mobileAndTabletCheck()
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [showPassword, setShowPassword] = useState(isMobile)
  const {classes} = useStyles()
  const {t} = useTranslation()
  const history = useHistory()
  const {globalState, setGlobalState} = useGlobalState()
  const {enqueueSnackbar} = useSnackbar()

  let helperText2: string | null = null

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onShowPassword = () => setShowPassword(!showPassword)

  switch (error) {
    case '403_PASSWORD_POLICY':
      helperText2 = t('authenticator.errorMessages.403_PASSWORD_POLICY')
      break

    case 'PASSWORDS_MUST_MATCH':
      helperText2 = t('authenticator.changePassword.passwords_must_match')
      break

    default:
      helperText2 = null
  }

  const nextButtonDisabled =
    isLoading ||
    !globalState.newPassword1 ||
    !globalState.newPassword2 ||
    !isPasswordValid(globalState.newPassword1)

  const handleError = (errorKey: string) => {
    if (isFormError(errorKey)) {
      setError(errorKey)
      return
    }
    enqueueSnackbar(t(`authenticator.errorMessages.${errorKey}`), {
      variant: 'error'
    })
  }

  const onPasswordChangeSubmit = async () => {
    setGlobalState((g) => ({
      ...g,
      newPassword1: g.newPassword1.trim(),
      newPassword2: g.newPassword2.trim()
    }))

    if (globalState.newPassword1 !== globalState.newPassword2) {
      setError('PASSWORDS_MUST_MATCH')
      setLoading(false)
      return
    }

    setError(null)
    setLoading(true)

    try {
      if (globalState.identityServerReturnUrl) {
        await changeUserPasswordForAuthCode(api)(globalState.password, globalState.newPassword1)
      } else if (globalState.pwlessPasswordReset) {
        const {token} = globalState

        const accessToken = token!.access_token

        const authApi = createAuthHttpClient(accessToken)
        await authApi.post('/passwordless/setPassword', {newPassword: globalState.newPassword1})
      } else {
        await api.post('/users/changePassword', {
          username: globalState.username,
          oldPassword: globalState.password,
          newPassword: globalState.newPassword1
        })
      }
      setGlobalState({...globalState, password: globalState.newPassword1})
      history.push(routes.ChangePasswordSuccess)
    } catch (error) {
      const e = error as AxiosError

      console.error(e)
      trackEvent('authError', {
        product: 'authenticator',
        date: new Date().toISOString(),
        errorCode: e.response?.status,
        component: 'StepChangePassword.tsx',
        endpoint: e.response?.config.url
      })
      handleError(getErrorKey(e))
    } finally {
      setLoading(false)
    }
  }

  return (
    <form
      className={classes.passwordRoot}
      data-test-id="page-signin-change-password"
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
      }}
    >
      <PaperTitle title={t('authenticator.changePassword.title')} isCentered={false} />
      <Box style={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
        <InputTextField
          value={globalState.newPassword1}
          name="password"
          onKeyDown={() => {
            setError(null)
          }}
          autoComplete="new-password"
          autoFocus={false}
          data-test-id="authenticator-new-password"
          onChange={(e) => setGlobalState({...globalState, newPassword1: e.target.value})}
          error={false}
          isPassword={true}
          passwordOptions={{showPassword, setShowPassword}}
          label={t('authenticator.changePassword.enter_password')}
        />

        <InputTextField
          value={globalState.newPassword2}
          onKeyDown={() => {
            setError(null)
          }}
          name="password"
          autoComplete="new-password"
          isPassword={true}
          passwordOptions={{showPassword, setShowPassword}}
          autoFocus={false}
          data-test-id="authenticator-new-password-confirmation"
          onChange={(e) => setGlobalState({...globalState, newPassword2: e.target.value})}
          error={!!helperText2}
          label={t('authenticator.changePassword.repeat_password')}
          endAdornment={<Box></Box>}
        />
      </Box>

      {!!helperText2 && (
        <InputMessage message={t('authenticator.changePassword.passwords_must_match')} />
      )}

      <PasswordValidation newPassword1={globalState.newPassword1} />

      <LoadingButton
        disabled={nextButtonDisabled}
        fullWidth
        style={{marginTop: '20px'}}
        type="submit"
        color="primary"
        variant="outlined"
        loading={isLoading}
        onClick={onPasswordChangeSubmit}
        data-test-id="authenticator-submit-new-password"
      >
        {t('authenticator.changePassword.next')}
      </LoadingButton>
    </form>
  )
}
