import React, {useState} from 'react'

export const useOTPCode = (otp: string, setOtp: (otp: string) => void, otpLength: number) => {
  const [activeInput, setActiveInput] = useState(0)
  const getOTPValue = () => (otp ? otp.toString().split('') : [])
  const calculateNextActiveInput = (inputValue: number) => {
    const nextInput = Math.max(Math.min(otpLength - 1, inputValue), 0)
    setActiveInput(nextInput)
  }
  const setInputValue = (value: string) => {
    const otpValue = getOTPValue()
    otpValue[activeInput] = value[0]

    setOtp(otpValue.join(''))
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    if (value.length === otpLength) {
      handleOnPaste(undefined, event.target.value)
      return
    }

    setInputValue(event.target.value)
    calculateNextActiveInput(activeInput + 1)
  }

  const isInputValueValid = (value: string) => {
    const isTypeValid = !isNaN(Number(value))
    return isTypeValid && value.trim().length === 1
  }
  const handleOnInput = (event: React.FormEvent<HTMLInputElement>) => {
    if (!isInputValueValid(event.currentTarget.value)) {
      event.currentTarget.value = ''
    }
  }

  const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>, index: number) => {
    calculateNextActiveInput(index)
    event.target.select()
  }

  const handleOnPaste = (event?: React.ClipboardEvent<HTMLDivElement>, clipboardData?: string) => {
    event?.preventDefault()

    const selectedClipboardData = event ? event.clipboardData.getData('text/plain') : clipboardData

    const otp = getOTPValue()
    let nextActiveInput = activeInput

    const pastedData = selectedClipboardData?.slice(0, otpLength - activeInput).split('')

    if (pastedData?.some((value) => isNaN(Number(value)))) {
      return
    }

    for (let pos = 0; pos < otpLength; ++pos) {
      if (pos >= activeInput && pastedData && pastedData.length > 0) {
        otp[pos] = pastedData.shift() ?? ''
        nextActiveInput++
      }
    }

    calculateNextActiveInput(nextActiveInput)
    setOtp(otp.join(''))
  }

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const otpValue = getOTPValue()

    switch (event.key) {
      case 'Backspace': {
        event.preventDefault()
        setInputValue('')
        calculateNextActiveInput(activeInput - 1)
        break
      }
      case 'Delete': {
        event.preventDefault()
        setInputValue('')
        break
      }
      case otpValue[activeInput]: {
        event.preventDefault()
        calculateNextActiveInput(activeInput + 1)
        break
      }
      default:
        break
    }
  }

  return {
    activeInput,
    otpValue: getOTPValue,
    handleOnChange,
    handleOnInput,
    handleOnFocus,
    handleOnKeyDown,
    handleOnPaste
  }
}
