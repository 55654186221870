import 'core-js/stable'
import 'regenerator-runtime/runtime'

import {ApiProvider} from '@hconnect/common/hooks/useApi'
import {AppInsightsAdapter} from '@hconnect/common/logging'
import {withTheme} from '@hconnect/uikit'
import {overrideHConnectTheme} from '@hconnect/uikit/src/lib2'
import {CircularProgress, CssBaseline as CssBaselineMui} from '@mui/material'
import {ThemeProvider} from '@mui/material/styles'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import i18next from 'i18next'
import {identity} from 'lodash'
import React, {Suspense} from 'react'
import {createRoot} from 'react-dom/client'
import {I18nextProvider} from 'react-i18next'
import {
  QueryClient as QueryClientV3,
  QueryClientProvider as QueryClientProviderV3
} from 'react-query'
import {BrowserRouter} from 'react-router-dom'

import {api} from './api/api'
import {App} from './App'
import {GlobalStateProvider} from './hooks/useGlobalState'
import Localization from './Localization'
import {FeaturesProvider} from './providers/features/FeaturesProvider'
import * as serviceWorker from './serviceWorker'

AppInsightsAdapter.init()

void Localization()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

const queryClientV3 = new QueryClientV3({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

const customBreakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1280,
    xl: 1920
  }
}

const authenticatorTheme = (overrideHConnectTheme ?? identity)({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          body: {
            height: '100vh',
            backgroundColor: 'white !important'
          }
        }
      }
    }
  },
  breakpoints: customBreakpoints
})

const render = (Component) => {
  const ThemedAuthenticator = withTheme({
    overrides: {
      MuiCssBaseline: {
        '@global': {
          body: {
            height: '100vh',
            backgroundColor: 'white !important'
          }
        }
      }
    }
  })(Component)

  const rootElement = document.getElementById('root')
  const root = createRoot(rootElement as HTMLElement)
  root.render(
    <Suspense fallback={<CircularProgress />}>
      <ApiProvider secureApi={null} publicApi={api}>
        <ThemeProvider theme={authenticatorTheme}>
          <CssBaselineMui />
          <QueryClientProvider client={queryClient}>
            <QueryClientProviderV3 client={queryClientV3}>
              <I18nextProvider i18n={i18next}>
                <GlobalStateProvider>
                  <FeaturesProvider>
                    <BrowserRouter>
                      <ThemedAuthenticator />
                    </BrowserRouter>
                  </FeaturesProvider>
                </GlobalStateProvider>
              </I18nextProvider>
            </QueryClientProviderV3>
          </QueryClientProvider>
        </ThemeProvider>
      </ApiProvider>
    </Suspense>
  )
}

render(App)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
