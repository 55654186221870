export const formatIdentityServerReturnUrl = (url: URL) => {
  const identityUrlToCheck = url.origin + url.pathname
  if (process.env.REACT_APP_STAGE === 'qa') {
    const qaIdentityUrl = new URL(
      'https://auth-qa.hconnect.digital/api/identity/connect/authorize/callback'
    )
    const qaIdentityUrlToCheck = qaIdentityUrl.origin + qaIdentityUrl.pathname

    if (identityUrlToCheck !== qaIdentityUrlToCheck) {
      return new URL(qaIdentityUrl.pathname + url.search + url.hash, qaIdentityUrl.origin)
    }
  }

  if (process.env.REACT_APP_STAGE === 'uat') {
    const uatIdentityUrl = new URL(
      'https://auth-uat.hconnect.digital/api/identity/connect/authorize/callback'
    )
    const uatIdentityUrlToCheck = uatIdentityUrl.origin + uatIdentityUrl.pathname

    if (identityUrlToCheck !== uatIdentityUrlToCheck) {
      return new URL(uatIdentityUrl.pathname + url.search + url.hash, uatIdentityUrl.origin)
    }
  }

  if (process.env.REACT_APP_STAGE === 'prod') {
    const prodIdentityUrl = new URL(
      'https://auth.hconnect.digital/api/identity/connect/authorize/callback'
    )
    const prodIdentityUrlToCheck = prodIdentityUrl.origin + prodIdentityUrl.pathname

    if (identityUrlToCheck !== prodIdentityUrlToCheck) {
      return new URL(prodIdentityUrl.pathname + url.search + url.hash, prodIdentityUrl.origin)
    }
  }
}
