import {TextField, TextFieldProps} from '@mui/material'
import React, {useEffect, useRef} from 'react'

import {useStyles} from './CodeInput.styles'

type CodeInputProps = TextFieldProps & {
  shouldFocus: boolean
  handleOnFocus: (event: React.FocusEvent<HTMLInputElement>) => void
}
export const CodeInput = ({shouldFocus, handleOnFocus, ...props}: CodeInputProps) => {
  const input = useRef<HTMLInputElement>(null)
  const componentMounted = useRef(false)
  const {classes} = useStyles()

  useEffect(() => {
    if (shouldFocus) {
      input.current?.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (componentMounted.current && shouldFocus) {
      input.current?.focus()
    }
    componentMounted.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFocus])

  return (
    <TextField
      type="tel"
      inputRef={input}
      className={classes.inputStyle}
      onFocus={handleOnFocus}
      value={props.value || ''}
      {...props}
    />
  )
}
