import {LayoutConstants} from '@hconnect/uikit'
import {makeStyles} from 'tss-react/mui'

export const useAppStyles = makeStyles()((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  rootContainer: {
    background:
      'linear-gradient(90deg, rgba(0,55,77,1) 0%, rgba(0,99,138,1) 40%,rgba(0,99,138,1) 60%, rgba(0,55,77,1) 100%)',
    [theme.breakpoints.down('sm')]: {
      background: 'none !important'
    }
  },
  cbRootContainer: {
    background: 'rgba(242, 245, 247, 1)',
    [theme.breakpoints.down('sm')]: {
      background: 'none !important'
    }
  },
  logoBar: {
    display: 'flex',
    flex: '0 1 55px',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#00374c',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      minHeight: '80px'
    },
    [theme.breakpoints.up('sm')]: {
      height: '100px'
    }
  },
  logo: {
    height: '100%'
  },
  paperAndFooter: {
    height: '100%'
  },
  snackbarDefault: {
    backgroundColor: '#000000dd',
    color: '#ffffffde'
  },
  mobileNav: {
    width: '100vw',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px 0 ${theme.spacing(3)}px`,
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  authMoleculeContainer: {
    display: 'flex',
    flex: '1 0 auto',
    paddingTop: '40px',
    paddingBottom: '40px',
    justifyContent: 'stretch',
    '@media screen and (max-width: 600px)': {
      paddingTop: 0,
      paddingBottom: 0
    }
  },

  footerContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    alignItems: 'center',
    fontSize: 12,
    width: '100%',
    color: theme.palette.secondary.main,
    borderTop: '1px solid #97979733',
    minHeight: 55,
    '@media screen and (max-width: 600px)': {
      borderTop: 0,
      backgroundColor: 'white',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  iconContainer: {
    position: 'absolute',
    left: '50%',
    maxHeight: '30px',
    marginTop: -9,
    marginLeft: -9,
    '@media screen and (max-width: 600px)': {
      left: theme.spacing(2),
      marginLeft: 0
    }
  },
  authMolecule: {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  white: {fill: 'white'},
  paper: {
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'column',
    padding: '48px',
    minHeight: '500px',
    '@media screen and (max-width: 600px)': {
      flexGrow: 1,
      width: '100% !important',
      padding: '32px 24px 24px 24px',
      borderRadius: '0 !important'
    }
  },
  announcementRoot: {
    width: '100%',
    minHeight: 32,
    verticalAlign: 'middle',
    display: 'flex',
    padding: theme.spacing(),
    lineHeight: 1.2,
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    }
  },
  announcementContent: {
    width: LayoutConstants.DEFAULT_CONTENT_WIDTH,
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    columnGap: 4
  },
  announcementWarning: {
    background: theme.palette.warning.main,
    color: theme.palette.warning.contrastText
  },
  announcementContainer: {
    position: 'sticky',
    zIndex: 1099,
    width: '100%',
    top: 0
  }
}))

export const useComponentStyles = makeStyles()((theme) => ({
  paper: {
    height: '100%',
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    width: '580px',
    padding: theme.spacing(10),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: theme.spacing(2),
      borderRadius: 0
    }
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    paddingTop: '30px'
  }
}))

export const useTextStyles = makeStyles()(() => ({
  text: {
    color: 'inherit',
    fontFamily: 'Roboto,sans-serif'
  },

  small: {
    fontSize: '0.86rem'
  },

  default: {
    fontSize: '1rem'
  },

  large: {
    fontSize: '1.14rem'
  },

  // FIXME: we should get rid of this. We cannot specify exact fontWeight as number.
  // font weights https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight
  normal: {
    fontWeight: 'lighter'
  },

  medium: {
    fontWeight: 'normal'
  },

  bold: {
    fontWeight: 'bold'
  },

  link: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    },
    color: '#29AAFF',
    fontFamily: 'Roboto,sans-serif'
  }
}))

export const useColorStyles = makeStyles()(() => ({
  text: {
    color: 'rgba(0, 55, 77, 0.96)'
  },

  textContrast: {
    color: 'rgba(255, 255, 255, 0.96)'
  },

  fillContrast: {
    fill: 'rgba(255, 255, 255, 0.96)'
  },

  textFaded: {
    color: '#7F9BA6'
  }
}))

// TODO: replace with TAILWIND CSS?
export const useLayoutStyles = makeStyles()(() => ({
  mt4: {
    marginTop: '4px'
  },

  mt8: {
    marginTop: '8px'
  },

  cursorPointer: {
    cursor: 'pointer'
  },

  flx: {
    display: 'flex'
  },

  absolute: {position: 'absolute'},
  relative: {position: 'relative'},
  flx1: {flex: 1},
  flxRow: {flexDirection: 'row'},
  flxRowReverse: {flexDirection: 'row-reverse'},
  flxCol: {flexDirection: 'column'},
  flxColReverse: {flexDirection: 'column-reverse'},
  flxWrap: {flexWrap: 'wrap'},
  aifs: {alignItems: 'flex-start'},
  aic: {alignItems: 'center'},
  aife: {alignItems: 'flex-end'},
  jcc: {justifyContent: 'center'},
  jcfs: {justifyContent: 'flex-start'},
  jcfe: {justifyContent: 'flex-end'},
  jcsb: {justifyContent: 'space-between'},
  jcsa: {justifyContent: 'space-around'},
  asfs: {alignSelf: 'flex-start'},
  asfe: {alignSelf: 'flex-end'},
  asc: {alignSelf: 'center'},
  ass: {alignSelf: 'stretch'}
}))
