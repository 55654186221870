import {LinearProgress} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router'

import {api} from '../../api/api'
import {getCurrentUserInfo, logoutCurrentUser} from '../../api/user'

export const Account = () => {
  const history = useHistory()
  const [isLoading, setLoading] = useState(true)
  // TODO: @MHC fix this
  const [currentUserInfo, setCurrentUserInfo] = useState<any>(null)

  useEffect(() => {
    const loadCurrentUserInfo = async () => {
      const userInfo = await getCurrentUserInfo(api)()
      setCurrentUserInfo(userInfo)
      setLoading(false)
    }

    if (isLoading) {
      void loadCurrentUserInfo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!isLoading && !currentUserInfo) {
    history.push('/')
  }

  if (isLoading) {
    return <LinearProgress />
  }

  return (
    <div>
      Logged In as {currentUserInfo.name}
      <br />
      <button onClick={() => ssoLogout()}>Logout</button>
    </div>
  )
}

export const ssoLogout = async (logoutId = '') => {
  try {
    const loggedOut = await logoutCurrentUser(api)(logoutId)

    if (loggedOut) {
      if (loggedOut.signOutIFrameUrl) {
        const iframe = document.createElement('iframe')
        iframe.width = '0'
        iframe.height = '0'
        iframe.src = loggedOut.signOutIFrameUrl
        iframe.onload = function () {
          if (loggedOut.postLogoutRedirectUri) {
            window.location = loggedOut.postLogoutRedirectUri
          } else {
            window.location.href = window.document.referrer
          }
        }
        const body: any = document.getElementsByTagName('body')[0]
        body.appendChild(iframe)
      }
    }
  } catch (error: any) {
    const {response} = error

    const invalidIdsReturnUrl = !!response && response.status === 405

    if (invalidIdsReturnUrl) {
      window.location.href = window.document.referrer
    }
  }
}
