import {trackEvent} from '@hconnect/common/logging/Analytics'
import {AxiosError} from 'axios'
import jwtDecode from 'jwt-decode'
import {get} from 'lodash'
import {useSnackbar} from 'notistack'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {api} from '../../api/api'
import {login, loginForAuthCode} from '../../api/user'
import LoadingButton from '../../Components/LoadingButton'
import {PaperTitle} from '../../Components/PaperTitle'
import {getErrorKey} from '../../errorHandling'
import {useGlobalState} from '../../hooks/useGlobalState'
import {routes} from '../../routes'
import {createOauthObject} from '../../utils'

import {isFormError} from './SignIn'
import {useStyles} from './SignIn.styles'
import {formatIdentityServerReturnUrl} from './utils/formatIdentityServerReturnUrl'

export const StepChangePasswordSuccess: React.FC = () => {
  const [isLoading, setLoading] = useState(false)
  const {classes} = useStyles()
  const {t} = useTranslation()
  const history = useHistory()
  const {globalState} = useGlobalState()
  const {enqueueSnackbar} = useSnackbar()

  const handleError = (errorKey: string) => {
    if (isFormError(errorKey)) {
      return
    }
    enqueueSnackbar(t(`authenticator.errorMessages.${errorKey}`), {
      variant: 'error'
    })
  }

  const onPasswordSubmit = async () => {
    setLoading(true)

    const {username, password, identityServerReturnUrl} = globalState

    try {
      if (identityServerReturnUrl) {
        const validIdentityServerUrl = formatIdentityServerReturnUrl(
          identityServerReturnUrl instanceof URL
            ? identityServerReturnUrl
            : new URL(identityServerReturnUrl)
        )

        const authCodeResponse = await loginForAuthCode(api)(
          username,
          password,
          validIdentityServerUrl
            ? validIdentityServerUrl.toString()
            : identityServerReturnUrl.toString(),
          false,
          null,
          null,
          true
        )

        setLoading(false)

        globalState.identityServerReturnUrl = authCodeResponse.idsReturnUrl

        if (authCodeResponse.isPasswordChangeRequired) {
          history.push(routes.ChangePassword)
          return
        }
      } else {
        const oauthParams = createOauthObject(globalState)
        const tokenInfo = await login(api)(username, password, oauthParams)
        setLoading(false)

        let isPasswordChangeRequired = false
        const decoded = jwtDecode(tokenInfo.access_token)
        isPasswordChangeRequired =
          get(decoded, ['isPasswordChangeRequired'], 'false').toLowerCase() === 'true'

        if (isPasswordChangeRequired) {
          history.push(routes.ChangePassword)
          return
        }

        globalState.token = tokenInfo
      }

      history.push(routes.RequestUserDataVerification)
    } catch (error) {
      const e = error as AxiosError

      console.error(e)
      trackEvent('authError', {
        product: 'authenticator',
        date: new Date().toISOString(),
        errorCode: e.response?.status,
        component: 'StepChangePasswordSuccess.tsx',
        endpoint: e.response?.config.url
      })

      handleError(getErrorKey(e))
      setLoading(false)
    }
  }

  return (
    <div
      className={classes.changePasswordSuccessContainer}
      data-test-id="page-signin-password-changed"
    >
      <PaperTitle
        title={t('authenticator.changePasswordSuccess.title')}
        subtitle={t('authenticator.changePasswordSuccess.subtitle')}
        isCentered={false}
      />
      <LoadingButton
        loading={isLoading}
        fullWidth
        type="submit"
        color="primary"
        variant="outlined"
        onClick={onPasswordSubmit}
        data-test-id="authenticator-submit-new-password-confirmation"
      >
        {t('authenticator.changePasswordSuccess.next')}
      </LoadingButton>
    </div>
  )
}
