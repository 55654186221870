import {InputTextField} from '@hconnect/uikit/src/lib2'
import React, {ChangeEventHandler} from 'react'
import {useTranslation} from 'react-i18next'

import {PasswordValidation} from '../../Pages/SignIn/PasswordValidation'

import {AccountFormComponentProps, AccountFormTypes} from './types'

interface Props extends AccountFormComponentProps {
  value: string
  onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  formMethods: AccountFormTypes
}

export const PasswordInput: React.FC<Props> = ({name, value, onChange, formMethods}) => {
  const {t} = useTranslation()

  return (
    <>
      <InputTextField
        id="passwordField"
        name={name || ''}
        label={t('authenticator.requestAccess.textfieldLabels.password')}
        autoComplete="new-password"
        data-test-id="enterPasswordField"
        isPassword={true}
        value={value}
        onChange={onChange}
        error={!!formMethods.formState.errors.password}
      />

      <PasswordValidation newPassword1={value} />
    </>
  )
}
