import {useContext} from 'react'

import {FeaturesContext} from '../../context/features/FeaturesContext'

export const useAuthFeatures = () => {
  const authFeatures = useContext(FeaturesContext)

  if (authFeatures === undefined) {
    throw new Error('FeaturesContext is not available')
  }

  return authFeatures
}
